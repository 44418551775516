import {    
    PODCAST_PAUSE,
    PODCAST_PLAY,
    PODCAST_VOLUME_OFF,
    PODCAST_VOLUME_ON,
    UPDATE_ALL_PODCASTS,
    UPDATE_CURRENT_PODCAST
} from '../../constants/podcasts';


export const playPodcast = () => dispatch => dispatch({ type : PODCAST_PLAY });
export const pausePodcast = () => dispatch => dispatch({ type : PODCAST_PAUSE });
export const turnOnVolume = () => dispatch => dispatch({ type : PODCAST_VOLUME_ON });
export const turnOffVolume = () => dispatch => dispatch({ type : PODCAST_VOLUME_OFF });

export const updateAllPodcats = (infos) => dispatch => dispatch({type : UPDATE_ALL_PODCASTS , payload : infos?.all || []  });
export const updateCurrentPordcat = infos => dispatch => dispatch({ type : UPDATE_CURRENT_PODCAST , payload : {...infos}  })

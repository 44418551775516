import firebaseApp, { db, storage } from "../../../config/firebase/init";

import md5 from "md5";
// import firebase from "firebase";
// import { v4 as uuidv4 } from "uuid";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import {
  USER_LOADING,
  SET_USER_INFOS,
  SET_USER_LOGGED_STATUS,
  SET_USER_ERROR,
  IS_CHECKING_USER_AUTH,
  UPDATE_ALL_POSTS,
  UPDATE_CURRENT_POST,
  RESET_USER_INFOS,
  UPDATE_CURRENT_USER_PODCAST,
  UPDATE_ALL_USER_PODCASTS,
  REMOVE_USER_PODCAST,
  UPDATE_CURRENT_USER_PROGRAM,
  UPDATE_ALL_USER_PROGRAMS,
  REMOVE_USER_PROGRAM,
} from "../../constants/user";

import { OPEN_SUBSCRIBE_FORM_MODAL } from "../../constants/shared";
import { message } from "antd";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";

//!USERS COLLECTION
const usersCollection = collection(db, "users");
const storageRef = storage;

//!AUTHENTIFICATION
export const authenticateUser = () => async (dispatch) => {
  try {
    dispatch({ type: IS_CHECKING_USER_AUTH });
    const auth = getAuth(firebaseApp);

    console.log(auth, "authauthauthauthauth");

    onAuthStateChanged(auth, async (userCredential) => {
      if (userCredential) {
        // const userRef = usersCollection.doc(userCredential.uid);
        try {
          // const usersCollection = collection(db, "users");
          // const userRef = await usersCollection.doc(userCredential.uid);
          const userRef = doc(db, "users", userCredential.uid);

          const userCollection = await getDoc(userRef);

          if (userCollection.exists()) {
            const userInfos = userCollection.data();
            console.log("userInfos", userInfos);

            // console.log(userInfos)
            const finalUser = {
              ...userInfos,
              id: userCredential.uid,
              fullname: userCredential.displayName,
              photoUrl: userCredential.photoURL,
              email: userCredential.email,
            };

            // console.log('userCredential' , userCredential)
            // dispatch({ type : UPDATE_ALL_USER_PODCASTS , payload : [...(userInfos?.podcasts || [])] })
            dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
            dispatch({
              type: UPDATE_ALL_POSTS,
              payload: [...(userInfos?.postsCategories || [])],
            });
            dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });

            const userPodcastsref = collection(
              db,
              "users",
              userCredential.uid,
              "podcasts"
            );
            const userProgramsRef = collection(
              db,
              "users",
              userCredential.uid,
              "programs"
            );

            //  const userPostsCategoriesRef = collection(
            //    db,
            //    "users",
            //    userCredential.uid,
            //    "postsCategories"
            //  );

            const progs = await getDocs(userPodcastsref);
            const pods = await getDocs(userProgramsRef);
            // const posts = await getDocs(userPostsCategoriesRef)

            const userPodcastsDocs = progs.docs;
            const userProgramsDocs = pods.docs;

            const userPodcastsData = [];
            const userProgramsData = [];
            userPodcastsDocs.forEach((userPodcastsDoc) => {
              userPodcastsData.push(userPodcastsDoc.data());
            });

            userProgramsDocs.forEach((userProgramDoc) => {
              userProgramsData.push(userProgramDoc.data());
            });

            dispatch({
              type: UPDATE_ALL_USER_PROGRAMS,
              payload: [...userProgramsData],
            });

            dispatch({
              type: UPDATE_ALL_USER_PODCASTS,
              payload: [...userPodcastsData],
            });

            // const userPostsCategories = userRef.collection('postsCategories');
            // const userPostsCategoriesDocs = await userPostsCategories.get()
            // userPostsCategoriesDocs.forEach(userPostsCategoriesDoc => {
            //     // userPodcastsData.push(userPostsCategoriesDoc.data())
            //     console.log('userPostsCategoriesDoc.data()' , userPostsCategoriesDoc.data())
            // })
          } else {
            const finalUser = {
              id: userCredential.uid,
              fullname: userCredential.displayName,
              photoUrl: userCredential.photoURL,
              email: userCredential.email,
            };

            dispatch({ type: UPDATE_ALL_POSTS, payload: [] });
            dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
            dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
            await setDoc(userRef, {
              id: userCredential.uid,
              fullname: userCredential.displayName,
            });
          }
        } catch (error) {
          dispatch({ type: SET_USER_ERROR, payload: error.message });
          dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
        }
      } else {
        dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
      }
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_USER_ERROR, payload: error.message });
    dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
  }
};

export const signInUser =
  ({ user }) =>
    async (dispatch) => {
      const { email, password } = user;
      const auth = getAuth(firebaseApp);

      try {
        dispatch({ type: SET_USER_ERROR, payload: "" });
        dispatch({ type: USER_LOADING });
        // const userCredential = await firebaseApp
        //   .auth()
        //   .signInWithEmailAndPassword(email, password);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        // const userRef = usersCollection.doc(userCredential.user.uid);
        const userRef = doc(db, "users", userCredential.user.uid);

        // console.log(userCredential)
        const userCollection = await getDoc(userRef);
        if (userCollection.exists()) {
          const userInfos = userCollection.data();
          // console.log(userInfos);
          const finalUser = {
            ...userInfos,
            id: userCredential.user.uid,
            fullname: userCredential.user.displayName,
            photoUrl: userCredential.user.photoURL,
          };

          dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
          dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
        } else {
          dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
        }
      } catch (error) {
        console.error(error);
        dispatch({ type: SET_USER_ERROR, payload: error.message });
        dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
      }
    };

export const signUpUser =
  ({ user }) =>
    async (dispatch) => {
      const {
        email,
        password,
        name,
        company,
        agreement,
        companySector,
        lastname,
        gender,
        banque,
        cin,
        formJuridique,
        ice,
        numberRc,
        DenominationEntreprise,
        cityRc,
      } = user;
      try {
        dispatch({ type: SET_USER_ERROR, payload: "" });
        dispatch({ type: USER_LOADING });

        const auth = getAuth(firebaseApp);
        const createdUser = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        // console.log(createdUser)
        const currentUser = createdUser.user;

        await updateProfile(currentUser, {
          displayName: `${lastname} ${name}`,
          photoURL: `http://gravatar.com/avatar/${md5(
            createdUser.user.uid
          )}?d=identicon`,
        });

        // const userRef = usersCollection.doc(createdUser.user.uid);
        const userRef = doc(db, "users", createdUser.user.uid);
        const docSnapshot = await getDoc(userRef);

        if (!docSnapshot.exists()) {
          await setDoc(
            userRef,
            JSON.parse(
              JSON.stringify({
                name,
                lastname,
                email,
                company,
                companySector,
                hasAcceptedTermsAndConditions: agreement,
                gender,

                banque,
                cin,
                formJuridique,
                ice,
                numberRc,
                DenominationEntreprise,
                cityRc,
              })
            )
          );

          const finalUser = {
            id: currentUser.uid,
            name,
            lastname,
            email,
            company,
            companySector,
            gender,
            hasAcceptedTermsAndConditions: agreement,
            fullname: `${lastname} ${name}`,
            photoUrl: currentUser.photoURL,
            banque,
            cin,
            formJuridique,
            ice,
            numberRc,
            DenominationEntreprise,
            cityRc,
          };
          dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
          dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
        } else {
          dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
          throw new Error("Utilisateur inexistant ! merci de créer un compte");
        }
      } catch (error) {
        console.dir(error, "Dave error");
        dispatch({ type: SET_USER_ERROR, payload: error.message });
        dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
      }
    };

export const signOutUser = () => async (dispatch) => {
  try {
    const auth = getAuth(firebaseApp);
    await auth.signOut();
    dispatch({ type: SET_USER_LOGGED_STATUS, payload: false });
    dispatch({ type: RESET_USER_INFOS });
    window.location.href = "/";
  } catch (error) {
    dispatch({ type: SET_USER_ERROR, payload: error.message });
  }
};

//!OTHER ACTIONS

export const addUserFundingTypesData = (infos) => async (dispatch) => {
  const auth = getAuth(firebaseApp);

  onAuthStateChanged(auth, async (userCredential) => {
    try {
      if (userCredential) {
        const userRef = doc(db, "users", userCredential.uid);
        // const userRef = usersCollection.doc(userCredential.uid);

        // const userFundingCollection = userRef.collection("Funding");
        const userCollection = await userRef.get();
        if (userCollection.exists()) {
          const userFundingCollection = collection(userRef, "Funding");
          await addDoc(userFundingCollection, {
            ...infos,
          });
        }
      } else {
        console.log("not connected");
      }
    } catch (error) {
      console.log(error.message);
    }
  });
};

export const addPostToUser =
  ({ categoryID, categorySlug, categoryTitle, postID, postSlug, postTitle }) =>
    async (dispatch) => {
      const auth = getAuth(firebaseApp);

      const currentPostInfos = {
        categoryID,
        categorySlug,
        categoryTitle,
        postID,
        postSlug,
        postTitle,
      };

      onAuthStateChanged(auth, async (userCredential) => {
        try {
          dispatch({
            type: UPDATE_CURRENT_POST,
            payload: {
              id: currentPostInfos?.postID,
              loading: true,
              error: "",
            },
          });

          if (userCredential) {
            const userRef = doc(db, "users", userCredential.uid);
            // const postCategoriesCol = userRef.collection('postsCategories');

            // const postCategoriesDocRef = postCategoriesCol.doc(categoryID)

            // const userCollection = await userRef.get();

            // if(userCollection.exists){

            //     const postCategoriesDoc = await postCategoriesDocRef.get();

            //     if(postCategoriesDoc.exists){
            //         const postsCol =  postCategoriesDocRef.collection('posts');

            //         const postDocRef = postsCol.doc(postID);

            //         const postDoc = await postDocRef.get();

            //         if(postDoc.exists){
            //             throw new Error('Already exists ! ');
            //         }

            //         await postDocRef.set({
            //             id : postID,
            //             title: postTitle,
            //             slug : postSlug,
            //             categoryID,
            //             categoryTitle,
            //             categorySlug
            //         })

            //     }else{

            //         await postCategoriesDocRef.set({
            //             id : categoryID,
            //             title: categoryTitle,
            //             slug : categorySlug
            //         })

            //         const postsCol =  postCategoriesDocRef.collection('posts');
            //         const postDocRef = postsCol.doc(postID);

            //         await postDocRef.set({
            //             id : postID,
            //             title: postTitle,
            //             slug : postSlug,
            //             categoryID,
            //             categoryTitle,
            //             categorySlug
            //         })
            //     }

            //     dispatch({type : UPDATE_CURRENT_POST , payload : {
            //         id : '',
            //         loading : false,
            //         error : ''
            //     }});

            //     dispatch({ type : SHOW_MESSAGE , payload : {
            //         type : 'success',
            //         text : 'Ajouté avec succès'
            //     }})

            //     dispatch({ type : UPDATE_ALL_POSTS , payload : [{
            //         categoryID,
            //         categorySlug,
            //         categoryTitle,
            //         postID,
            //         postSlug,
            //         postTitle,
            //         id: postID
            //     }] });

            // }

            try {
              const userCollection = await getDoc(userRef);
              if (userCollection.exists()) {
                const userInfos = userCollection.data();
                // console.log(userInfos)

                try {
                  let finalData = [];
                  const allCategories = [...(userInfos?.postsCategories || [])];

                  let categoryIndex = allCategories?.findIndex(
                    (cat) => cat?.id === currentPostInfos?.categoryID
                  );

                  if (categoryIndex > -1) {
                    const posts =
                      allCategories[categoryIndex]?.posts?.findIndex(
                        (x) => x?.id === postID
                      ) > -1
                        ? allCategories[categoryIndex]?.posts
                        : [
                          ...allCategories[categoryIndex]?.posts,
                          { id: postID, title: postTitle, slug: postSlug },
                        ];
                    allCategories[categoryIndex] = {
                      ...allCategories[categoryIndex],
                      id: categoryID,
                      title: categoryTitle,
                      slug: categorySlug,
                      posts: [...posts],
                    };

                    finalData = [...allCategories];
                  } else {
                    finalData = [
                      ...allCategories,
                      {
                        id: categoryID,
                        title: categoryTitle,
                        slug: categorySlug,
                        posts: [{ id: postID, title: postTitle, slug: postSlug }],
                      },
                    ];
                  }

                  // console.log(finalData)

                  await updateDoc(userRef, {
                    postsCategories: [...finalData],
                  });
                  dispatch({
                    type: UPDATE_CURRENT_POST,
                    payload: {
                      id: "",
                      loading: false,
                      error: "",
                    },
                  });

                  // dispatch({ type : SHOW_MESSAGE , payload : {
                  //     type : 'success',
                  //     text : 'Ajouté avec succès'
                  // }})

                  message.success("Ajouté avec succès");

                  dispatch({ type: UPDATE_ALL_POSTS, payload: [...finalData] });
                } catch (error) {
                  // dispatch({ type : SET_USER_ERROR , payload : error.message });
                  console.error(error);
                  throw new Error("Impossible de récupérer vos informations");
                }
              } else {
                throw new Error("Impossible de récupérer vos informations");
              }
            } catch (error) {
              // dispatch({ type : SET_USER_ERROR , payload : error.message });
              console.error(error);
              throw new Error("Impossible de récupérer vos informations");
            }
          } else {
            dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: UPDATE_CURRENT_POST,
            payload: {
              id: "",
              loading: false,
              error: error?.message,
            },
          });

          // dispatch({ type : SHOW_MESSAGE , payload : {
          //     type : 'error',
          //     text : error?.message
          // }})

          message.error(error.message);
        }
      });
    };

export const removePostToUser =
  ({ categoryID, categorySlug, categoryTitle, postID, postSlug, postTitle }) =>
    async (dispatch) => {
      const auth = getAuth(firebaseApp);

      const currentPostInfos = {
        categoryID,
        categorySlug,
        categoryTitle,
        postID,
        postSlug,
        postTitle,
      };

      onAuthStateChanged(auth, async (userCredential) => {
        try {
          dispatch({
            type: UPDATE_CURRENT_POST,
            payload: {
              id: currentPostInfos?.postID,
              loading: true,
              error: "",
            },
          });

          if (userCredential) {
            const userRef = doc(db, "users", userCredential.uid);

            try {
              const userCollection = await getDoc(userRef);
              if (userCollection.exists()) {
                const userInfos = userCollection.data();
                // console.log(userInfos)

                try {
                  let finalData = [];
                  const allCategories = [...(userInfos?.postsCategories || [])];

                  let categoryIndex = allCategories?.findIndex(
                    (cat) => cat?.id === currentPostInfos?.categoryID
                  );

                  allCategories[categoryIndex] = {
                    ...allCategories[categoryIndex],
                    posts: allCategories[categoryIndex].posts?.filter(
                      (x) => x?.id !== currentPostInfos.postID
                    ),
                  };

                  finalData = [...allCategories];

                  //  await userRef.update({
                  //    postsCategories: [...finalData],
                  //  });

                  await updateDoc(userRef, {
                    postsCategories: [...finalData],
                  });

                  dispatch({
                    type: UPDATE_CURRENT_POST,
                    payload: {
                      id: "",
                      loading: false,
                      error: "",
                    },
                  });

                  // dispatch({ type : SHOW_MESSAGE , payload : {
                  //     type : 'success',
                  //     text : 'retiré avec succès'
                  // }})

                  message.success("retiré avec succès");

                  dispatch({ type: UPDATE_ALL_POSTS, payload: [...finalData] });
                } catch (error) {
                  // dispatch({ type : SET_USER_ERROR , payload : error.message });
                  console.error(error);
                  throw new Error("Impossible de récupérer vos informations");
                }
              } else {
                throw new Error("Impossible de récupérer vos informations");
              }
            } catch (error) {
              // dispatch({ type : SET_USER_ERROR , payload : error.message });
              console.error(error);
              throw new Error("Impossible de récupérer vos informations");
            }
          } else {
            throw new Error(
              "Merci de vous connecter pour acceder à cette fonctionnamité !"
            );
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: UPDATE_CURRENT_POST,
            payload: {
              id: "",
              loading: false,
              error: error?.message,
            },
          });
          // dispatch({ type : SHOW_MESSAGE , payload : {
          //     type : 'error',
          //     text : error.message
          // }})
          message.error(error.message);
        }
      });
    };

export const uploadUserProfilePicture =
  ({ file }) =>
    async (dispatch) => {
      try {
        const auth = getAuth(firebaseApp);
        onAuthStateChanged(auth, async (userCredential) => {
          if (userCredential) {
            const uid = userCredential.uid;
            const profileRef = ref(storage, `profiles/${file.name}#${uid}`);
            try {
              const snapshot = await uploadBytes(profileRef, file);
              // console.log(snapshot)
              console.log("Uploaded a blob or file!");
              const url = await getDownloadURL(snapshot.ref);

              updateProfile(auth.currentUser, {
                photoURL: url,
              });

              dispatch({ type: SET_USER_INFOS, payload: { photoUrl: url } });
              // dispatch({ type : SHOW_MESSAGE , payload : {
              //     type : 'success',
              //     text : 'Profil édité avec succès :)'
              // }})
              message.success("Profil édité avec succès :)");
            } catch (error) {
              console.error(error);
            }
          } else {
            dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
            // dispatch({ type : SHOW_MESSAGE , payload : {
            //     type : 'error',
            //     text : 'Merci de vous connecter pour effectuer cette action'
            // }})
            message.error("Merci de vous connecter pour effectuer cette action");
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

export const updateUserProfile =
  ({ user, reauth = false, userOldInfos = null, somethingChanged = true }) =>
    async (dispatch) => {
      const auth = getAuth();

      const { email, password, name, lastname } = user;
      const finalUser = JSON.parse(
        JSON.stringify({ ...user, fullname: `${lastname} ${name}` })
      );
      onAuthStateChanged(auth, async (authed) => {
        // console.log(authed)

        if (authed) {
          try {
            if (!somethingChanged) {
              // dispatch({ type : SHOW_MESSAGE , payload : {
              //     type : 'warning',
              //     text : 'Aucune modification apportée :('
              // }})
              message.warning("Aucune modification apportée :(");
              return;
            }

            dispatch({ type: SET_USER_ERROR, payload: "" });
            dispatch({ type: USER_LOADING });

            if (reauth) {
              const credential = EmailAuthProvider.credential(
                userOldInfos?.email,
                userOldInfos?.password
              );
              await reauthenticateWithCredential(auth.currentUser, credential);
              // console.log(userCredential)
              if (email) {
                await updateEmail(auth.currentUser, email);
              }

              if (password) {
                await updatePassword(auth.currentUser, password);
              }

              updateProfile(auth.currentUser, {
                displayName: `${lastname} ${name}`,
              });

              // const userRef = usersCollection.doc(authed.uid);
              const userRef = doc(db, "users", authed.uid);
              const docSnapshot = await getDoc(userRef);

              if (docSnapshot.exists()) {
                console.log(finalUser);
                if (finalUser.password) {
                  delete finalUser.password;
                }

                if (finalUser.confirmedPassword) {
                  delete finalUser.confirmedPassword;
                }
                await updateDoc(userRef, {
                  ...finalUser,
                });

                dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
                // dispatch({ type : SHOW_MESSAGE , payload : {
                //     type : 'success',
                //     text : 'Mise a jour effectuée :)'
                // }})

                message.success("Mise a jour effectuée :)");
              } else {
                throw new Error(
                  "Votre collection n'existe pas dans nos bases de données :("
                );
              }
            } else {
              // const createdUser = await firebaseApp.auth().createUserWithEmailAndPassword(email , password);
              // console.log(createdUser)
              // const currentUser = firebaseApp.auth().currentUser;

              updateProfile(auth.currentUser, {
                displayName: `${lastname} ${name}`,
              });

              // const { user : currentUser } = await authed.reauthenticateWithCredential(email , password);

              // const userRef = usersCollection.doc(authed.uid);
              const userRef = doc(db, "users", authed.uid);
              const docSnapshot = await getDoc(userRef);

              if (docSnapshot.exists()) {
                // console.log(finalUser)
                await updateDoc(userRef, {
                  ...finalUser,
                });

                dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
                // dispatch({ type : SHOW_MESSAGE , payload : {
                //     type : 'success',
                //     text : 'Mise a jour effectuée :)'
                // }})

                message.success("Mise a jour effectuée :)");
              } else {
                throw new Error(
                  "Votre collection n'existe pas dans nos bases de données :("
                );
              }
            }
          } catch (e) {
            dispatch({ type: SET_USER_ERROR, payload: e.message });
            // dispatch({ type : SHOW_MESSAGE , payload : {
            //     type : 'error',
            //     text : e.message
            // }})
            message.error(e.message);
          }
        } else {
          dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
          // dispatch({ type : SHOW_MESSAGE , payload : {
          //     type : 'error',
          //     text : 'Merci de vous connecter pour effectuer cette action'
          // }})

          message.error("Merci de vous connecter pour effectuer cette action");
        }
      });
    };

export const signInWithGoogle = () => async (dispatch) => {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  dispatch({ type: SET_USER_ERROR, payload: "" });
  dispatch({ type: USER_LOADING });
  const userCredential = await signInWithPopup(auth, googleProvider);
  // const q = query(usersCollection, where("capital", "==", true));

  //get user infos firebase V9

  // console.log('userCredential' , userCredential)
  const userRef = doc(db, "users", userCredential.user.uid);

  // const userRef = usersCollection.doc(userCredential.user.uid);

  const userCollection = await getDoc(userRef);

  if (!userCollection.exists()) {
    const finalUser = {
      id: userCredential.user.uid,
      fullname: userCredential.user.displayName,
      accessToken: userCredential.credential.accessToken,
      idToken: userCredential.credential.idToken,
      signInMethod: userCredential.credential.signInMethod,
      name: userCredential.additionalUserInfo.profile.family_name,
      lastname: userCredential.additionalUserInfo.profile.given_name,
      photoUrl: userCredential.additionalUserInfo.profile.picture,
      googleId: userCredential.additionalUserInfo.profile.id,
    };

    dispatch({ type: UPDATE_ALL_POSTS, payload: [] });
    dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
    dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });

    await setDoc(userRef, {
      ...finalUser,
    });
  }

  if (userCollection.exists()) {
    const userInfos = userCollection.data();
    // console.log(userInfos)
    const finalUser = {
      ...userInfos,
      id: userCredential.user.uid,
      fullname: userCredential.user.displayName,
      photoUrl: userCredential.user.photoURL,
    };

    dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
    dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
  }
};

export const signInWithFacebook = () => async (dispatch) => {
  const auth = getAuth();
  const facebookProvider = new FacebookAuthProvider();
  dispatch({ type: SET_USER_ERROR, payload: "" });
  dispatch({ type: USER_LOADING });

  // facebookProvider.setCustomParameters({
  //   display: "popup",
  // });

  try {
    const userCredential = await signInWithPopup(auth, facebookProvider);

    // console.log('userCredential' , userCredential)

    const userRef = usersCollection.doc(userCredential.user.uid);

    const userCollection = await getDoc(userRef);
    // .then((res) => {
    //     console.log(res.user)
    // }).catch((error) => {
    //     console.log(error.message)
    // })

    if (!userCollection.exists()) {
      const finalUser = {
        id: userCredential.user.uid,
        fullname: userCredential.user.displayName,
        accessToken: userCredential.credential.accessToken,
        signInMethod: userCredential.credential.signInMethod,
        name: userCredential.additionalUserInfo.profile.last_name,
        lastname: userCredential.additionalUserInfo.profile.first_name,
        photoUrl: userCredential.additionalUserInfo.profile.picture.data.url,
        facebookId: userCredential.additionalUserInfo.profile.id,
      };

      // dispatch({ type : UPDATE_ALL_POSTS , payload : [] });
      dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
      dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });

      await setDoc({
        ...finalUser,
      });
    }

    if (userCollection.exists()) {
      const userInfos = userCollection.data();
      // console.log(userInfos)
      const finalUser = {
        ...userInfos,
        id: userCredential.user.uid,
        fullname: userCredential.user.displayName,
        photoUrl: userCredential.user.photoURL,
      };

      dispatch({ type: SET_USER_LOGGED_STATUS, payload: true });
      dispatch({ type: SET_USER_INFOS, payload: { ...finalUser } });
    }
  } catch (error) {
    dispatch({ type: SET_USER_ERROR, payload: error.message });
  }
};

export const addProgramToUser = (program) => async (dispatch) => {
  const user = getAuth().currentUser;

  try {
    if (user) {
      const { id, title, slug } = program;

      dispatch({
        type: UPDATE_CURRENT_USER_PROGRAM,
        payload: {
          id,
          loading: true,
          error: "",
        },
      });

      console.log("user", user);

      // const userRef = usersCollection.doc(user.uid);
      const userRef = doc(db, "users", user.uid);

      // const userProgramsCollection = collection(userRef, "programs");
      const userCollection = await getDoc(userRef);

      if (userCollection.exists()) {
        const userProgramsDocRef = doc(db, "users", user.uid, "programs", id);
        const userPodcastsDoc = await getDoc(userProgramsDocRef);
        if (userPodcastsDoc.exists()) {
          throw new Error("Programme déjà existant");
        } else {
          // await userProgramsDocRef.set({
          //   id,
          //   title,
          //   slug,
          // });
          await setDoc(userProgramsDocRef, {
            id,
            title,
            slug,
          });
          dispatch({
            type: UPDATE_ALL_USER_PROGRAMS,
            payload: [
              {
                id,
                title,
                slug,
              },
            ],
          });

          dispatch({
            type: UPDATE_CURRENT_USER_PROGRAM,
            payload: {
              id: "",
              loading: false,
              error: "",
            },
          });

          message.success("Ajouté avec succès !");
        }
      }
    } else {
      dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
      dispatch({
        type: UPDATE_CURRENT_USER_PODCAST,
        payload: {
          id: "",
          loading: false,
          error: "",
        },
      });
    }
  } catch (error) {
    console.log(error);
    message.error(error?.message);

    dispatch({
      type: UPDATE_CURRENT_USER_PROGRAM,
      payload: {
        id: "",
        loading: false,
        error: "",
      },
    });
  }
};

export const removeProgramFromUser =
  (program) => async (dispatch, getState) => {
    try {
      const { id } = program;
      dispatch({
        type: UPDATE_CURRENT_USER_PROGRAM,
        payload: {
          id,
          loading: true,
          error: "",
        },
      });
      const user = getAuth().currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);

        // const userProgramsCollection = collection(userRef, "programs");
        const userCollection = await getDoc(userRef);

        if (userCollection.exists()) {
          const userProgramsDocRef = doc(db, "users", user.uid, "programs", id);
          // const userProgramsDocRef = userProgramsCollection.doc(id);
          // await userProgramsDocRef.delete();
          await deleteDoc(userProgramsDocRef);

          const {
            user: {
              programms: { all: allUserProgramms },
            },
          } = getState();

          dispatch({
            type: REMOVE_USER_PROGRAM,
            payload: [...(allUserProgramms?.filter((x) => x?.id !== id) || [])],
          });

          dispatch({
            type: UPDATE_CURRENT_USER_PROGRAM,
            payload: {
              id: "",
              loading: false,
              error: "",
            },
          });
        } else {
          dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
          dispatch({
            type: UPDATE_CURRENT_USER_PODCAST,
            payload: {
              id: "",
              loading: false,
              error: "",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error?.message);

      dispatch({
        type: UPDATE_CURRENT_USER_PROGRAM,
        payload: {
          id: "",
          loading: false,
          error: "",
        },
      });
    }
  };

export const addPodcastToUser = (podcastInfos) => async (dispatch) => {
  const { id } = podcastInfos;
  const auth = getAuth();

  onAuthStateChanged(auth, async (userCredential) => {
    try {
      if (userCredential) {
        dispatch({
          type: UPDATE_CURRENT_USER_PODCAST,
          payload: {
            id,
            loading: true,
            error: "",
          },
        });

        // const userRef = usersCollection.doc(userCredential.uid);
        const userRef = doc(db, "users", userCredential.uid);
        const userPOdcastsCollection = collection(userRef, "podcasts");
        const userCollection = await getDoc(userRef);

        if (userCollection.exists()) {
          const userPodcastsDocRef = doc(userPOdcastsCollection, id);
          const userPodcastsDoc = await getDoc(userPodcastsDocRef);

          if (userPodcastsDoc.exists()) {
            throw new Error("Already exists ! ");
          } else {
            await setDoc(userPodcastsDocRef, { ...podcastInfos });
          }

          dispatch({
            type: UPDATE_ALL_USER_PODCASTS,
            payload: [{ ...podcastInfos }],
          });

          dispatch({
            type: UPDATE_CURRENT_USER_PODCAST,
            payload: {
              id: "",
              loading: false,
              error: "",
            },
          });

          // dispatch({ type : SHOW_MESSAGE , payload : {
          //     type : 'success',
          //     text : 'Ajouté avec succès'
          // }})

          message.success("Ajouté avec succès");
        }
      } else {
        dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
        dispatch({
          type: UPDATE_CURRENT_USER_PODCAST,
          payload: {
            id: "",
            loading: false,
            error: "",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CURRENT_USER_PODCAST,
        payload: {
          id: "",
          loading: false,
          error: "",
        },
      });

      // dispatch({ type : SHOW_MESSAGE , payload : {
      //     type : 'error',
      //     text : error?.message
      // }})

      message.error(error?.message);
    }
  });
};

export const removePodcastToUser = (id) => async (dispatch, getState) => {
  // console.log(store.getState());
  const auth = getAuth();

  onAuthStateChanged(auth, async (userCredential) => {
    try {
      if (userCredential) {
        dispatch({
          type: UPDATE_CURRENT_USER_PODCAST,
          payload: {
            id,
            loading: true,
            error: "",
          },
        });

        // const userRef = usersCollection.doc(userCredential.uid);
        const userRef = doc(db, "users", userCredential.uid);
        const userPodcastsCollection = collection(userRef, "podcasts");

        await deleteDoc(doc(userPodcastsCollection, id));

        const {
          user: {
            podcasts: { all: allUserPodcasts },
          },
        } = getState();

        // console.log(allUserPodcasts?.filter( x => x?.id !== id ))

        dispatch({
          type: REMOVE_USER_PODCAST,
          payload: [...(allUserPodcasts?.filter((x) => x?.id !== id) || [])],
        });

        dispatch({
          type: UPDATE_CURRENT_USER_PODCAST,
          payload: {
            id: "",
            loading: false,
            error: "",
          },
        });

        // dispatch({ type : SHOW_MESSAGE , payload : {
        //     type : 'success',
        //     text : 'Retiré avec succès'
        // }})

        message.success("Retiré avec succès");
      } else {
        dispatch({ type: OPEN_SUBSCRIBE_FORM_MODAL });
        dispatch({
          type: UPDATE_CURRENT_USER_PODCAST,
          payload: {
            id: "",
            loading: false,
            error: "",
          },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type : SHOW_MESSAGE , payload : {
      //     type : 'error',
      //     text : error?.message
      // }})
      message.error(error.message);
    }
  });
};

import {
  USER_LOADING,
  SET_USER_INFOS,
  SET_USER_LOGGED_STATUS,
  SET_USER_ERROR,
  IS_CHECKING_USER_AUTH,
  UPDATE_ALL_POSTS,
  UPDATE_CURRENT_POST,
  RESET_USER_INFOS,
  UPDATE_ALL_USER_PODCASTS,
  UPDATE_CURRENT_USER_PODCAST,
  REMOVE_USER_PODCAST,
  SET_CURRENT_PODCAST_MESSAGE,
  RESET_CURRENT_PODCAST_MESSAGE,
  UPDATE_CURRENT_USER_PROGRAM,
  UPDATE_ALL_USER_PROGRAMS,
  REMOVE_USER_PROGRAM,
} from "../../constants/user";

const INITIAL_STATE = {
  isCheckingUserAuth: false,
  loggedIn: false,
  loading: false,
  infos: {},
  posts: {
    current: {
      id: "",
      loading: false,
      error: "",
    },
    all: [],
  },
  podcasts: {
    message: {
      show: false,
      id: "",
      text: "",
      type: "",
    },
    current: {
      id: "",
      loading: false,
      error: "",
    },
    all: [],
  },
  programms: {
    all: [],
    current: {
      id: "",
      loading: false,
      error: "",
    },
  },
  error: "",
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CURRENT_PODCAST_MESSAGE:
      return {
        ...state,
        podcasts: {
          ...state.podcasts,
          message: {
            ...state.podcasts.message,
            show: true,
            ...action.payload,
          },
        },
      };
    case RESET_CURRENT_PODCAST_MESSAGE:
      return {
        ...state,
        podcasts: {
          ...state.podcasts,
          message: {
            ...state.podcasts.message,
            ...INITIAL_STATE.podcasts.message,
          },
        },
      };
    case RESET_USER_INFOS:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case UPDATE_CURRENT_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          current: {
            ...state.posts.current,
            ...action.payload,
          },
        },
      };
    case UPDATE_ALL_POSTS:
      return {
        ...state,
        posts: {
          ...state.posts,
          all: [...action.payload],
        },
      };
    case UPDATE_CURRENT_USER_PROGRAM:
      return {
        ...state,
        programms: {
          ...state.programms,
          current: {
            ...state.programms.current,
            ...action.payload,
          },
        },
      };

    case UPDATE_ALL_USER_PROGRAMS:
      return {
        ...state,
        programms: {
          ...state.programms,
          all: [...state.programms.all, ...action.payload],
        },
      };

    case REMOVE_USER_PROGRAM:
      return {
        ...state,
        programms: {
          ...state.programms,
          all: [...action.payload],
        },
      };

    case UPDATE_CURRENT_USER_PODCAST:
      return {
        ...state,
        podcasts: {
          ...state.podcasts,
          current: {
            ...state.podcasts.current,
            ...action.payload,
          },
        },
      };
    case UPDATE_ALL_USER_PODCASTS:
      return {
        ...state,
        podcasts: {
          ...state.podcasts,
          all: [...state.podcasts.all, ...action.payload],
        },
      };
    case REMOVE_USER_PODCAST:
      return {
        ...state,
        podcasts: {
          ...state.podcasts,
          all: [...action.payload],
        },
      };

    case IS_CHECKING_USER_AUTH:
      return {
        ...state,
        isCheckingUserAuth: true,
      };
    case SET_USER_INFOS:
      return {
        ...state,
        loading: false,
        isCheckingUserAuth: false,
        infos: {
          ...state.infos,
          ...action.payload,
        },
      };
    case SET_USER_LOGGED_STATUS:
      return {
        ...state,
        loading: false,
        isCheckingUserAuth: false,
        loggedIn: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        loading: false,
        isCheckingUserAuth: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const PODCASTS_TRANSLATIONS_FR = {
  podcastsPageTitle: "Les podcasts INTALIQ",
  podcastsPageDescription: `Retrouvez
    tous les podcasts d’actualités et d’inspirations.`,
  podcastsPageContentTitle: "Grille des programmes",
  searchPodcastText: "Rechercher un podcast",
  accesPodcastBtn: "Accéder aux podcasts",
  programSchedule: "GRILLE DES PROGRAMMES",
  mostListened: "Les plus écoutés",
  mostSearchedTheme: "Les thèmes les plus recherchés",
  themes: "Thèmes",
  goToDate: "Aller à cette date",
  searchPodcasts: "Rechercher un podcast",
  searchPodcastsPlaceholder: "Rechercher un podcast",
  searchByMonth: "Rechercher par mois",
  pocasts: "Podcasts",
  podcasts: "Podcasts",
  addToMyList: "Ajouter à ma liste",
  LesPodcastsDeLentreprise: "Les podcasts de l'entreprise",
  min: "min",
  morePodcasts: "Plus de podcasts",
};

const HEADER_FR = {
  WIKINTALIQ: "Wikintaliq",
  JeVeuxCreeruneEntreprise: "Je veux créer une entreprise",
  JeSuisEntrepreneur: "Je suis entrepreneur",
  LAMEDIATHEQUES: "La Médiathèque",
  Videos: "Vidéos",
  PODCASTEHEADER: "Podcasts",
  Organismes: "Organismes",
  PourTrouverUnFinancement: "Pour trouver un financement",
  PourMAccompagner: "Pour m’accompagner",
  BLOG: "Blog",
  CONSULTEZLAFAQ: "Consultez la FAQ",
  consultTheguide: "Consulter le guide",
  ContactezNous: "Contactez nous",
  EACADEMY: "E-academy",
  DecouvrezGOBOT: "Découvrez GO-BOT",
  PourVousAccompagner: "Pour vous accompagner",
  EtRepondreAVosQuestions: "et répondre à vos questions",
  SuivezNous: "Suivez nous",
  sedeconnecter: "Se déconnecter",
  leblog: "Le blog",
  nosPodcasts: "Nos podcasts",
  LaPlateforme: "La plateforme ",
  DeLentrepreneur: "de l'entrepreneur",
};

const MEDIATHEQUEENTREPRENEURS_FR = {
  MediaEntrepreneursBlog: "La Médiathèque des entrepreneurs",
  ActuMediatheque: "Retrouvez tous les articles d’actualité et d’inspiration.",
  RetrouverVotreBibliotheque: "Retrouvez votre bibliothèque média intaliq.",
  acceesMediatheque: "Accéder à la médiathèque",
  theVideo: "LES VIDEOS",
  ofExperts: "d’experts",
  moreVideos: "plus de vidéos",
};

const GENERAL_TRANSLATIONS_FR = {
  nextTime: "Prochainement",
  goToDateText: "Aller à cette date",
  listenText: "Écouter",
  monthText: "Mois",
  yearText: "Année",
  addToMyWishlist: "Ajouter à ma liste",
  mostListened: "Les plus écoutés",
  popularThemes: "Les thèmes les plus recherchés",
  themes: "Thèmes",
  other: "Autre",
  login: "Se connecter",
  discoverIntaliq: "Intaliq en 2 minutes",
  MyProfile: "Mon profil",
  MyPodcasts: "Mes Podcasts",
  MyReadingList: "Ma liste de lecture",
  logout: "Déconnexion",
  RESTONSENCONTACT: "RESTONS EN CONTACT",
  paraFooter:
    "La  plateforme   destinée  aux  porteurs de projets,   start-ups, TPE et PME à la recherche d’informations utiles, de formations pragmatiques et  d’accompagnement  technique   et  financier pour développer leurs activités.",
  subParaFooter:
    "Cette plateforme a été réalisée avec le soutien financier de l’Union européenne. Son contenu relève de la seule responsabilité de la CGEM et ne reflète pas nécessairement les opinions de l’Union européenne.",
  JESUISENTREPRENEUR: "JE SUIS ENTREPRENEUR",
  GuidesDeFinancement: "Guides de financement",
  RechercherParMois: "Rechercher par mois",
  EntrepreneursConcernes: "Entrepreneurs concernés",
  GuidesIntaliq: "Guides Intaliq",

  DemarrerLaConversation: "Démarrer la conversation",
  DEMANDERAGOBOT: "DEMANDER À GO-BOT ",
  RencontrezGoBot: "Rencontrez Go-Bot",
  RestonsEnContact: "Restons en contact",
  MerciANosPartenaires: "Merci à nos partenaires",
  Accueil: "Accueil",

  LEBLOGDESENTREPRENEURS: "LE BLOG DES ENTREPRENEURS",
  foireAuxQuestions: "Foire aux questions",
  sloganBlog: "Retrouvez tous les articles d’actualité utile à l'entrepreneur",

  AhmedB: "Ahmed B., Entrepreneur - Agadir",
  Entrepreneuse: "Entrepreneuse",
  Casablanca: "Casablanca",
  Agadir: "Agadir",
  Tetouan: "Tétouan",
  Entrepreneur: "Entrepreneur",
  ExpertEnMarketing: "Expert en marketing",
  malak: "Malak A., Entrepreneur - Casablanca",
  Karim: "Karim S., Expert en marketing - Tetouan",

  QuestionsFrequemmentPosees: "Questions fréquemment posées",
  voirPlus: "voir plus",
  LeBlogDeLEntrepreneur: "Le blog de l’entrepreneur",

  trouver: "trouver",
  demandeAgoBot: "DEMANDER À GO-BOT",
  jechercheFinance: "Je cherche une source de financement",
  jecherchedAccompagnement: "Je cherche un organisme d'accompagnement",
  trouverMeilleurPartenaire:
    "Trouver le meilleur partenaire pour vous accompagner",
  accesServicesFinacier:
    "Accéder à l’ensemble des produits et services financiers disponibles et identifier le bon interlocuteur. ",

  MERCIANOSPARTENAIRES: "MERCI À NOS PARTENAIRES",
  LESORGANISMESDACCOMPAGNEMENT: "LES ORGANISMES D’ACCOMPAGNEMENT",

  ServicesProposes: "Services proposés",
  CoordonneesEtContacts: "Coordonnées et contacts",

  CATEGORIES: "CATÉGORIES",
  Tous: "Tous",
  Conseils: "Conseils",
  Actualites: "Actualités",
  Inspirations: "Inspirations",
  Faq: "FAQ",

  GuideEcosysteme: "Guide écosystème",

  EnSavoirPlus: "En savoir plus",
  RechercherUnOrganisme: "Rechercher un organisme ",

  CONNECTEZVOUSAVOTRECOMPTEINTALIQ: "CONNECTEZ-VOUS À VOTRE COMPTE INTALIQ",
  PasEncoreDeCompte: "Pas encore de compte",
  motDePasse: "Mot de passe*",
  Email: "Email",
  PasswordLenght: "Le mot de passe doit contenir au moins 6 caractères !*",
  InputRequired: "*Ce champ est obligatoire !",
  emailNotValid: "email non valide !",
  seConnecter: "Se connecter",
  Sinscrire: "S'inscrire",
  seConnecterAvec: "Se connecter avec",
  Ou: "Ou",
  delete: "Supprimer",
  VoulezVousVraimentLeSupprimer: "Voulez-vous vraiment le supprimer ?",
  non: "Non",
  oui: "Oui",
  readMore: "Lire plus",
  ReadOn: "Lire plus tard",

  noDataFound: "Aucune donnée trouvée",
  findAllInformationsFor: "Retrouvez toutes les informations nécessaires pour",
  yourEnterprise: "votre entreprise",
  create: "créer",
  manage: "gérer",
  develop: "développer",
  goToBlog: "Accéder au blog",
  discoverOurCompleteGuideTo: "Découvrez notre guide complet pour",
  creation: "Création",
  development: "Dévéloppement",
  mediaLibrary: "Médiathèque",
  companyPodcasts: "les podcasts de l'entreprise",
  download: "télécharger",
  findFinancing: "trouver un financement",
  findFinancingDescription: "Trouver votre organisme de financement",
  findApartner: "Trouver un partenaire",
  theOpportunity: "les opportunités",
  theOpportunityInvestment: "Des opportunités d’investissement",
  theOpportunityDescription:
    "Accédez à notre banque de projets pour trouver votre idée !",
  companySize: "Taille de l’entreprise",
  activitySector: "Secteur d'activité",
  investissementsOpportunities: "Opportunités d’investissements",
};

const PROFILEUSERS_FR = {
  profile: "Profil",
  PodcastsFavoris: " Podcasts favoris",
  ListDeLecture: "Liste de lecture",
  valider: "valider",
  settings: "Paramètre",
  name: "Nom*",
  lastname: "Prénom*",
  EntrepriseOuProjet: "Entreprise ou projet*",
  SecteurActivité: "Secteur d’activité",
  ConfirmerVotreMotDePasse: "Confirmer votre mot de passe*",
};

const HOMEPAGE_TRANSLATIONS_FR = {
  homeTitle: `La plateforme de l'entrepreneur`,
  homeDescription: `Vous méritez d’avoir toutes les ressources nécessaires pour réussir votre projet.`,
  firstBlockText: `guides opérationnels `,
  secondBlockText: `sources de financement`,
  thirdBlockText: `vidéos et podcasts par mois`,
  chatbotSectionTitle: "chatbotSectionTitle français",
  descriptionMediaLibrary:
    "Découvrez notre sélection de vidéos d’experts et podcasts inspirants",
  thePodcasts: "les podcasts",
  fromTheCompany: "De l'entreprise",
  gettingSupportDescription:
    "Intaliq vous aide à trouver le meilleur partenaire pour vous accompagner !",
  financingEcosystemGuide: "financement & guide écosystème",
};

const NEWSLETTER_FR = {
  abonezVous: "Abonnez-vous à la",
  newsletterIntaliq: "newsletter intaliq",
  actuInatliq: "pour recevoir toute l’actualité entrepreneuriale au Maroc",
  yourMail: "Votre adresse mail",
  signup: "je m’inscris",
  textNewsLetter: "newsletter",
  sloganNewsLetter:
    "Abonnez-vous à la newsletter intaliq pour recevoir toute l'actualité entreprenariale au Maroc",
};

const ENTREPRISE_FR = {
  EntrepriseEnDeveloppement: "Entreprise en développement",
  EntrepriseEnCroissance: `Entreprise en croissance`,
  EntrepriseEnDeclinRestructuration: "Entreprise en déclin/restructuration",
  EntrepriseEnTransmission: "Entreprise en transmission",
  PorteurDidee: "Porteur d’idée",
  PorteurDeProjet: "Porteur de projet",
  Startup: "Startup",
};

const MENU_FR = {
  findOutMore: "S'informer",
  gettingSupport: "Se faire accompagner",
  findingOpportunities: "Trouver des opportunités",
  toolbox: "Boîte à outils",
  quickSearch: "Recherche rapide",
  findYourFinancing: "Trouver votre financement",
  findApartner: "Trouver un partenaire",
  training: "Se Former",
  projectInvestmentBanking: "Banque de projets & Investissement",
  servicesOffered: "Services proposés",
};

const HERO_FR = {
  searchATopic: "Rechercher un sujet",
  smallTextHero:
    "Exemple : Financer ma startup, recruter des talents, trouver un associé...",
};

const TOOLBOX_FR = {
  entrepreneursToolbox: "la boîte à outils de l'entrepreneur",
  homeDescriptionToolbox:
    "Retrouvez une sélection de documents types pour vous aider dans votre quotidien d'entrepreneur.",
  descriptionToolbox:
    "Découvrez notre sélection de documents types pour vous aider dans votre quotidien d'entrepreneur.",
};

const TRAINING_FR = {
  trainingTitle: "UN PARCOURS DE FORMATION EN LIGNE DÉDIÉ À L’ENTREPRENEUR",
  trainingDescription:
    "La 212Founders Academy est une nouvelle formation lancée par 212Founders pour répondre aux besoins des startups en phase d'idéation",
  intaliqAcademy: "intaliq academy",
  intaliqButton: "Accès au programme",
  trainingHeroDescription:
    "Un parcours de formation en ligne créer par 212Founders",
  trainingHomeDescription:
    "En partenariat avec 212 founders, Intaliq vous propose un programme de formation sur 10 semaines pour passer de l’idée au financement",
  foundersAcademy: "ACCÈS AU PARCOURS 212 FOUNDERS",
  knowhowTitle: "L’ACADÉMIE KNOW HOW",
  knowhowDescription: "Une panoplie de formations et des conseils gratuits pour vous aider à faire face aux défis de la gestion d’une entreprise.",
  knowhowAccessButton: "ACCÈS AU PARCOURS",
  traningLoginButton: "SE CONNECTER POUR ACCÉDER",
};

const FOOTER_FR = {
  policy: "Conditions générales d’utilisation",
  allRightsReserved: "Copyright © 2022 CGEM. Tous droits réservés",
  organisms: "Organismes",
  allRights: "Réalisé  par la CGEM",
};

const ENTREPRENEURS_FR = {
  AbdessamadM: "Abdessamad M.",
  AbdessamadEntreprise: "Digital brains",
  Hamza: "Hamza R.",
  HamzaEntreprise: "Sowit",
  Maha: "Maha H.",
  MahaEntreprise: "MynProd",
};

const BLOG_FR = {
  blog: "Blog",
};

export const TRANSLATIONS_FR = {
  ...PODCASTS_TRANSLATIONS_FR,
  ...GENERAL_TRANSLATIONS_FR,
  ...HOMEPAGE_TRANSLATIONS_FR,
  ...NEWSLETTER_FR,
  ...HEADER_FR,
  ...MEDIATHEQUEENTREPRENEURS_FR,
  ...ENTREPRISE_FR,
  ...PROFILEUSERS_FR,
  ...FOOTER_FR,
  ...BLOG_FR,
  ...ENTREPRENEURS_FR,
  ...MENU_FR,
  ...HERO_FR,
  ...TOOLBOX_FR,
  ...TRAINING_FR,
};

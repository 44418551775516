import { SmileOutlined } from "@ant-design/icons";

import {
  SET_CURRENT_URL,
  SET_PAGE_INFOS,
  OPEN_CHATBOT,
  OPEN_MENU,
  CLOSE_CHATBOT,
  CLOSE_MENU,
  OPEN_HEADER_VIDEO,
  CLOSE_HEADER_VIDEO,
  SET_APP_CURRENT_LINK,
  SET_APP_PREVIOUS_LINKS,
  RESET_APP_BREADCRUMB,
  OPEN_FUNDING_CHATBOT,
  CLOSE_FUNDING_CHATBOT,
  CHATBOT_OPENED,
  CHATBOT_CLOSED,
  OPEN_SUBSCRIBE_FORM_MODAL,
  CLOSE_SUBSCRIBE_FORM_MODAL,
  SHOW_MESSAGE,
  CLOSE_MESSAGE,
  CHANGE_CURRENT_LANGUAGE,
  SHOW_AUDIO_PLAYER,
  HIDE_AUDIO_PLAYER,
  UPDATE_URL_STATE,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_CURRENT_PAGE,
  SET_CURRENT_AR_URL,
  SET_CURRENT_FR_URL,
  OPEN_MODAL_CHATBOT,
  CLOSE_MODAL_CHATBOT,
} from "../../constants/shared";

const initialState = {
  url: null,
  urlState: {},
  isAudioPlayerVisible: false,
  currentLang: "fr",
  pageInfos: {
    title: "",
    description: "",
    imageSrc: "",
  },
  menuOpen: false,
  openModalChatBot: false,
  subscribeForm: {
    show: false,
    closable: true,
  },
  chatbotOpen: false,
  chatBotOpen: false,
  fundingChatBotOpen: false,
  videoOpen: false,
  breadcrumb: {
    previousLinks: [],
    currentLink: {
      isDropDown: false,
      dropDownLinks: [],
      selectedDropDownLink: "",
      urlText: "",
    },
  },
  messageInfos: {
    show: false,
    type: "",
    text: "",
  },
  notificationInfos: {
    show: false,
    message: "",
    description: "",
    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
  },
  currentPage: "",
  currentFrUrl: "",
  currentArUrl: "",
};

const sharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_URL:
      return { ...state, url: action.payload };
    case SET_CURRENT_AR_URL:
      return { ...state, currentArUrl: action.payload };
    case SET_CURRENT_FR_URL:
      return { ...state, currentFrUrl: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notificationInfos: {
          ...state.notificationInfos,
          show: true,
          ...action.payload,
        },
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notificationInfos: {
          show: false,
          ...state.notificationInfos,
        },
      };
    case UPDATE_URL_STATE:
      return {
        ...state,
        urlState: {
          ...state.urlState,
          ...action.payload,
        },
      };
    case SHOW_AUDIO_PLAYER:
      return {
        ...state,
        isAudioPlayerVisible: true,
      };
    case HIDE_AUDIO_PLAYER:
      return {
        ...state,
        isAudioPlayerVisible: false,
      };
    case CHANGE_CURRENT_LANGUAGE:
      return { ...state, currentLang: action.payload };
    case SHOW_MESSAGE:
      return {
        ...state,
        messageInfos: {
          ...state.messageInfos,
          show: true,
          ...action.payload,
        },
      };
    case CLOSE_MESSAGE:
      return {
        ...state,
        messageInfos: {
          ...state.messageInfos,
          show: false,
          type: "",
          text: "",
        },
      };
    case OPEN_SUBSCRIBE_FORM_MODAL:
      return {
        ...state,
        subscribeForm: {
          ...state.subscribeForm,
          show: true,
          ...action.payload,
        },
      };
    case CLOSE_SUBSCRIBE_FORM_MODAL:
      return {
        ...state,
        subscribeForm: {
          ...state.subscribeForm,
          show: false,
          closable: true,
        },
      };
    case SET_PAGE_INFOS:
      return {
        ...state,
        pageInfos: {
          ...state.pageInfos,
          title: action.payload.title,
          description: action.payload.description,
          imageSrc: action.payload.imageSrc,
        },
      };
    case OPEN_MENU:
      return { ...state, menuOpen: true };
    case OPEN_MODAL_CHATBOT:
      return { ...state, openModalChatBot: true };
    case OPEN_CHATBOT:
      return { ...state, chatBotOpen: true };
    case CHATBOT_OPENED:
      return { ...state, chatbotOpen: true };
    case CHATBOT_CLOSED:
      return { ...state, chatbotOpen: false };
    case OPEN_FUNDING_CHATBOT:
      return { ...state, fundingChatBotOpen: true };
    case OPEN_HEADER_VIDEO:
      return { ...state, videoOpen: true };
    case CLOSE_MENU:
      return { ...state, menuOpen: false };
    case CLOSE_MODAL_CHATBOT:
      return { ...state, openModalChatBot: false };
    case CLOSE_CHATBOT:
      return { ...state, chatBotOpen: false };
    case CLOSE_FUNDING_CHATBOT:
      return { ...state, fundingChatBotOpen: false };
    case CLOSE_HEADER_VIDEO:
      return { ...state, videoOpen: false };
    case SET_APP_PREVIOUS_LINKS:
      return {
        ...state,
        breadcrumb: {
          ...state.breadcrumb,
          previousLinks: action.payload,
        },
      };
    case SET_APP_CURRENT_LINK:
      return {
        ...state,
        breadcrumb: {
          ...state.breadcrumb,
          currentLink: {
            ...state.breadcrumb.currentLink,
            ...action.payload,
          },
        },
      };
    case RESET_APP_BREADCRUMB:
      return {
        ...state,
        breadcrumb: {
          ...state.breadcrumb,
          previousLinks: [],
          currentLink: {
            isDropDown: false,
            dropDownLinks: [],
            selectedDropDownLink: "",
            urlText: "",
          },
        },
      };
    default:
      return state;
  }
};

export default sharedReducer;

import { lazy, memo, Suspense, useEffect } from "react";
import {
  ConfigProvider,
  BackTop,
  Modal,
  Skeleton,
  Row,
  Col,
} from "antd";

import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import botAnimation from "./assets/animations/Posez-votre-question-ici.json";

//!COMPONENTS
import BackDropLoader from "./components/Library/BackdropLoader";
import {
  chatbotOpen,
  handleCloseModalChatBot,
} from "./redux/actions/shared";

import i18n from "./translations/i18n";
// import { withNamespaces } from 'react-i18next';
import AppAudioPlayer from "./components/Library/AppAudioPlayer";
import Message from "./components/Library/Chatbot/components/Message";
import { resetChatbot } from "./redux/actions/chatbot";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LogoIntaliqColored from "./assets/images/logo/logo-intaliq.png";
import LogoCgem2 from "./assets/images/logo/new-logo-cgem-v2.png";
// import AppMessage from "./components/Library/AppMessage";

//!ROUTES
const Routes = lazy(() => import("./routes"));

//!COMPONENTS
const AppAuth = lazy(() => import("./components/Library/AppAuth"));
const Chatbot = lazy(() => import("./components/Library/Chatbot"));
const LottieAnimate = lazy(() => import("./components/Library/LottieAnimate"));
const Footer = lazy(() => import("./components/Footer"));
const Header = lazy(() => import("./components/Header"));

const App = () => {
  const dispatch = useDispatch();
  const sharedState = useSelector((state) => state.shared);

  // useEffect(() => {
  //   if (!localStorage.getItem("loggedIn")) {
  //     let password = "";
  //     do {
  //       password = prompt("Please enter the password");
  //     } while (password !== "cgem-2022");
  //     localStorage.setItem("loggedIn", true);
  //   }
  // }, []);

  useEffect(() => {
    i18n.changeLanguage(sharedState.currentLang);
  }, [sharedState.currentLang]);

  useEffect(() => {
    if (
      window.location.hostname.includes("cgem-6e73f.web.app") ||
      window.location.hostname.includes("cgem-6e73f.firebaseapp.com")
    ) {
      window.location.href = "https://intaliq.ma";
    }

    const head = document.querySelector("head");
    if (sharedState.currentLang === "ar") {
      const appStyle = document.createElement("style");
      appStyle.setAttribute("id", "app_style");
      appStyle.innerHTML = `
        *,*::after , *::before , p{
          font-family: 'Cairo', sans-serif !important;
        }
      `;
      head.appendChild(appStyle);
    }

    if (
      document.querySelector("#app_style") &&
      sharedState.currentLang === "fr"
    ) {
      head.removeChild(document.querySelector("#app_style"));
    }

    // if (process.env.NODE_ENV !== "development") {
    //   console.clear();
    // }
  }, [sharedState.currentLang]);

  const handleClick = () => dispatch(chatbotOpen());

  return (
    <Suspense fallback={<BackDropLoader />}>
      <ConfigProvider
        direction={`${sharedState.currentLang === "ar" ? "rtl" : "ltr"}`}
      >
        <div className={`App ${sharedState.currentLang === "ar" ? "ar" : ""}`}>
          <Router>
            {sharedState.currentPage !== "home" && <Header />}
            <Routes />

            <Footer />

            {sharedState.currentPage !== "home" && (
              <>
                {(sharedState.url !== "/" ||
                  sharedState.url?.split("/ar")[1]?.length < 1) && (
                    <div
                      className={`chatbot-animation-fixed ${sharedState.isAudioPlayerVisible ? "withAudioPlayer" : ""
                        }`}
                      role="button"
                      onClick={handleClick}
                      style={{ display: "none" }}
                    >
                      <LottieAnimate animation={botAnimation} />
                    </div>
                  )}
              </>
            )}
            <ModalResultChatBotInfo />
            <Chatbot />
            <AppAudioPlayer />
            <AppAuth />

            {/* <AppMessage /> */}
          </Router>
        </div>
        <BackTop target={() => document.querySelector("body")} />
      </ConfigProvider>
    </Suspense>
  );
};

export default App;

const ModalResultChatBotInfo = memo(function Greeting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { messages, loading } = useSelector(
    (state) => state.chatbot
  );
  const { openModalChatBot } = useSelector((state) => state.shared);

  function filterObjectsByTexts(array, textsToExclude) {
    return array.filter((item) => {
      const text = item.content?.text || "";
      return !textsToExclude.includes(text);
    });
  }

  const textsToExclude = [
    "Bonjour, Je suis Go-bot, votre guide personnel. Je suis là pour vous orienter et répondre à vos questions.",
    "Cette recherche a-t-elle été utile ? Avez-vous besoin d'autres informations ?",
  ];

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(handleCloseModalChatBot());
      dispatch(resetChatbot());
    };

    // Abonnement aux changements de route
    const unlisten = history.listen(handleRouteChange);

    // Nettoyage de l'abonnement lorsque le composant est démonté
    return () => {
      unlisten();
    };
  }, [dispatch, history]);

  return (
    <Modal
      centered
      width={"70%"}
      style={{ zIndex: 1000000000 }}
      bodyStyle={{
        background:
          "transparent linear-gradient(244deg, #8c00cb 0%, #3de0fe 100%) 0% 0% no-repeat padding-box",
      }}
      footer={null}
      open={openModalChatBot} // Changed 'open' to 'visible'
      onCancel={() => {
        dispatch(handleCloseModalChatBot());
        dispatch(resetChatbot());
      }}
    >
      <Row justify={"center"}>
        <Col>
          <img src={LogoIntaliqColored} alt="intaliq" width={200} />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col>
          <img src={LogoCgem2} alt="intaliq" width={50} />
        </Col>
      </Row>
      {loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <div className="app__chatbot-body--wrapper">
          {filterObjectsByTexts(messages, textsToExclude).map(
            (message, index) => (
              <Message key={index} message={message} />
            )
          )}
        </div>
      )}

      {/* <Reply replies={reply} /> */}
    </Modal>
  );
});

//

import "./Text.css";

const Text = ({ text, left = true }) => {
  return (
    <div className="chatbot__text-container">
      <p className={`chatbot__text ${left ? "left" : "right"}`}> {text}</p>
    </div>
  );
};

export default Text;

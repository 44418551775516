import {
  ArrowRightOutlined,
  ArrowDownOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  pausePodcast,
  playPodcast,
  updateCurrentPordcat,
} from "../../../../../redux/actions/podcasts";
// import { removeTags, sanitizeHTML } from '../../../../../utils/utilFunctions';

//!STYLE
import "./Cards.css";
import { handleCloseModalChatBot } from "../../../../../redux/actions/shared";

const Cards = ({ cards, cardType }) => {
  console.log(cards, cardType);
  const { current, playing } = useSelector((state) => state.podcasts);
  const dispatch = useDispatch();

  const [numberToShow, setNumberToShow] = useState(10);

  const getUrl = ({ type, slug, parentSlug, id, parentId }) => {
    // console.log(type , id)
    switch (type?.toLowerCase()) {
      case "posts":
        return !!parentSlug
          ? `/guide/${parentSlug}/#${slug || ""}`
          : `/guide/${slug || ""}`;
      case "post":
        return !!parentSlug
          ? `/guide/${parentSlug}/#${slug || ""}`
          : `/guide/${slug || ""}`;
      case "blog_posts":
        return `/blog/${slug}`;
      case "blog":
        return `/blog/${slug || ""}`;
      case "organisms":
        return `/organism/${slug || ""}`;
      case "organisme":
        return `/organism/${slug || ""}`;
      case "faqs":
        return `/faq/${parentId || ""}#${id}`;
      case "mutimedias":
        return `/mediatheque/${id || ""}`;
      default:
        return "#";
    }
  };

  const handleClick = (podcast) => {
    const { id, authorName, title, songUrl, imageUrl } = podcast;
    if (current?.id !== id || !playing) {
      const pod = {
        id,
        author: authorName,
        title,
        imageUrl,
        songUrl,
      };
      dispatch(updateCurrentPordcat({ ...pod }));
      // dispatch(showAudioPlayer());
      dispatch(playPodcast());
    }

    if (current?.id === id && playing) {
      dispatch(pausePodcast());
    }
  };

  return (
    <div className="cardsMessage">
      {/* <p style = {{ marginTop : 30 }}>Voici les articles qui corespondent à votre recherche...</p> */}
      {
        <Row gutter={[10, 20]}>
          {cards
            .filter((x, i) => i < numberToShow)
            .map((card, index) => (
              <Col xd={24} md={24} lg={12}>
                <div key={card?.id} className="cardMessage">
                  <h4 className="title text-uppercase">
                    {" "}
                    {index + 1}. {card?.title}{" "}
                  </h4>
                  <p
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: card?.excerpt || card?.content,
                    }}
                  />
                  {cardType?.toLowerCase() !== "program" &&
                    cardType?.toLowerCase() !== "podcasts" && (
                      <Link
                        to={getUrl({
                          type: cardType,
                          slug: card?.slug,
                          parentSlug: card?.parentSlug,
                          id: card?.id,
                          parentId: card?.parentId,
                        })}
                      >
                        {" "}
                        Lire la suite <ArrowRightOutlined />{" "}
                      </Link>
                    )}
                  {cardType?.toLowerCase() === "podcasts" && (
                    <Button
                      onClick={() =>
                        handleClick({
                          id: card?.id,
                          authorName: card?.podcast_infos?.authorName || "",
                          title: card?.title,
                          songUrl: card?.podcast_infos?.upload?.mediaItemUrl,
                          imageUrl: card?.featuredImage?.node?.mediaItemUrl,
                        })
                      }
                      icon={
                        current?.id === card?.id && playing ? (
                          <PauseCircleOutlined />
                        ) : (
                          <PlayCircleOutlined />
                        )
                      }
                      type="primary"
                      style={{ color: "#7E00D3", margin: 0 }}
                    >
                      Lire le podcast{" "}
                    </Button>
                  )}
                </div>
              </Col>
            ))}
        </Row>
      }
      {cards.length > numberToShow && (
        <Button
          shape="round"
          type="primary"
          style={{ color: "#555555" }}
          onClick={() => setNumberToShow(cards.length)}
        >
          Plus de résultat <ArrowDownOutlined />{" "}
        </Button>
      )}
    </div>
  );
};

export default Cards;

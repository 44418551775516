import React, { memo } from "react";
import botIcon from "../../../../../assets/icons/bot-icon.svg";
import Button from "../Button";
import Cards from "../Cards";
import ChatbotModal from "../Modal";
import ModalTabs from "../ModalTabs";
import ChatbotTabs from "../Tabs";
import Text from "../Text";

import "./Message.css";
import { Skeleton } from "antd";

const ButtonGroup = ({ buttons }) => (
  <div className="d-flex" style={{ marginLeft: 60, flexWrap: "wrap" }}>
    {buttons.map((btn) => (
      <div style={{ marginRight: 10 }} key={btn.id}>
        <Button text={btn.text} />
      </div>
    ))}
  </div>
);

const MessageContent = ({ message }) => {
  switch (message.type) {
    case "button":
      return <ButtonGroup buttons={message.content} />;
    case "text":
      return <Text text={message.content.text} left={message.isChatbot} />;
    case "cards":
      return <Cards cards={message.content} cardType={message.cardType} />;
    case "tabs":
      return <ChatbotTabs content={message.content} />;
    case "modal":
      return <ChatbotModal content={message.content} />;
    case "modalTabs":
      return <ModalTabs content={message.content} />;
    default:
      return (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      );
  }
};

const Message = ({ message }) => {
  return (
    <div className={`chatbot__message ${!message?.isChatbot ? "reverse" : ""}`}>
      <div className="owner-infos d-flex flex-column align-items-center">
        {/* {message?.isChatbot && (
          <img src={botIcon} alt="" style={{ width: 30 }} />
        )} */}
        {/* {!message?.isChatbot && (
          <span>
            {" "}
            {message?.time ||
              `${new Date().getHours()} : ${new Date().getMinutes()}`}{" "}
          </span>
        )} */}
        {/* {message?.isChatbot && <span> Go-Bot </span>} */}
      </div>
      <div className="content">
        <MessageContent message={message} />
      </div>
    </div>
  );
};

export default memo(Message);

import { 
    FUNDING_UPDATE_CONVERSATION , 
    FUNDING_BOT_LOADING , 
    FUNDING_REQUEST_FAILED , 
    FUNDING_RESET_CONVERSATION , 
    FUNDING_UPDATE_USER_CHOICE 
} from '../../constants/fundingConversation';

const initialState = {
    loading : true,
    error: null,
    messages: [], 
    user : {
        id : '',
        choice : {}
    }
}

export const fundingConversationReducer = ( state = initialState , action ) => {
    switch (action.type) {
        case FUNDING_RESET_CONVERSATION : 
            return {
                ...state,
                loading : true,
                error: null,
                messages: []
            }
        case FUNDING_UPDATE_CONVERSATION:
            return {
                ...state,
                loading : false,
                messages : [
                    ...state.messages,
                    {
                        ...action.payload
                    }
                ]
            }
        case FUNDING_BOT_LOADING : 
            return {
                ...state,
                loading : true
            }            
        case FUNDING_REQUEST_FAILED : 
        return {
            ...state,
            loading : false,
            error: action.payload
        }
        case FUNDING_UPDATE_USER_CHOICE : 
            return {
                ...state,
                user : {
                    ...state.user,
                    choice : {
                        ...state.user.choice,
                        ...action.payload
                    }
                }
            }
        default:
            return state
    }
}
import {
  SET_CURRENT_URL,
  SET_PAGE_INFOS,
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_CHATBOT,
  CLOSE_CHATBOT,
  OPEN_HEADER_VIDEO,
  CLOSE_HEADER_VIDEO,
  SET_APP_CURRENT_LINK,
  SET_APP_PREVIOUS_LINKS,
  RESET_APP_BREADCRUMB,
  OPEN_FUNDING_CHATBOT,
  CLOSE_FUNDING_CHATBOT,
  CHATBOT_OPENED,
  CHATBOT_CLOSED,
  OPEN_SUBSCRIBE_FORM_MODAL,
  CLOSE_SUBSCRIBE_FORM_MODAL,
  SHOW_MESSAGE,
  CLOSE_MESSAGE,
  CHANGE_CURRENT_LANGUAGE,
  SHOW_AUDIO_PLAYER,
  HIDE_AUDIO_PLAYER,
  UPDATE_URL_STATE,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_CURRENT_PAGE,
  SET_CURRENT_AR_URL,
  SET_CURRENT_FR_URL,
  OPEN_MODAL_CHATBOT,
  CLOSE_MODAL_CHATBOT,
  OPEN_MENUV2,
  CLOSE_MENUV2,
} from "../../constants/shared";

export const setCurrentArUrl = (url) => (dispatch) =>
  dispatch({ type: SET_CURRENT_AR_URL, payload: url });
export const setCurrentFrUrl = (url) => (dispatch) =>
  dispatch({ type: SET_CURRENT_FR_URL, payload: url });

export const setCurrentPage = (page) => (dispatch) =>
  dispatch({ type: SET_CURRENT_PAGE, payload: page });
export const setCurrentUrl = (url) => (dispatch) =>
  dispatch({ type: SET_CURRENT_URL, payload: url });

export const setPageInfos = (pageInfos) => (dispatch) => {
  dispatch({
    type: SET_PAGE_INFOS,
    payload: {
      title: pageInfos.title,
      description: pageInfos.description,
      imageSrc: pageInfos.imageSrc || "",
    },
  });
};

export const showMessage = (infos) => (dispatch) =>
  dispatch({ type: SHOW_MESSAGE, payload: { ...infos } });
export const closeMessage = () => (dispatch) =>
  dispatch({ type: CLOSE_MESSAGE });

export const setCurrentLink = (currentLink) => (dispatch) => {
  dispatch({
    type: SET_APP_CURRENT_LINK,
    payload: {
      isDropDown: currentLink.isDropDown || false,
      dropDownLinks: currentLink.dropDownLinks || [],
      selectedDropDownLink: currentLink.selectedDropDownLink || "",
      urlText: currentLink.urlText || "",
    },
  });
};

export const showNotification = (infos) => (dispacth) =>
  dispacth({ type: SHOW_NOTIFICATION, payload: { ...infos } });
export const closeNotification = () => (dispacth) =>
  dispacth({ type: CLOSE_NOTIFICATION });

export const updateUrlState = (state) => (dispatch) =>
  dispatch({ type: UPDATE_URL_STATE, payload: { ...state } });

export const changeLanguage = (lang) => (dispatch) =>
  dispatch({ type: CHANGE_CURRENT_LANGUAGE, payload: lang || "fr" });

export const setPreviousLinks = (previous) => (dispatch) =>
  dispatch({ type: SET_APP_PREVIOUS_LINKS, payload: previous || [] });

export const reset_app_breadcrumb = () => (dispatch) =>
  dispatch({ type: RESET_APP_BREADCRUMB });

export const openHeaderVideo = () => (dispatch) =>
  dispatch({ type: OPEN_HEADER_VIDEO });
export const closeHeaderVideo = () => (dispatch) =>
  dispatch({ type: CLOSE_HEADER_VIDEO });

export const openMenu = () => (dispatch) => dispatch({ type: OPEN_MENU });
export const closeMenu = () => (dispatch) => dispatch({ type: CLOSE_MENU });

export const openMenuV2 = () => (dispatch) => dispatch({ type: OPEN_MENUV2 });
export const closeMenuV2 = () => (dispatch) => dispatch({ type: CLOSE_MENUV2 });

export const handleOpenModalChatBot = () => (dispatch) =>
  dispatch({ type: OPEN_MODAL_CHATBOT });
export const handleCloseModalChatBot = () => (dispatch) =>
  dispatch({ type: CLOSE_MODAL_CHATBOT });

export const openSubscribeFormModal = (infos) => (dispatch) =>
  dispatch({
    type: OPEN_SUBSCRIBE_FORM_MODAL,
    payload: !!infos ? { ...infos } : { closable: true },
  });
export const closeSubscribeFormModal = () => (dispatch) =>
  dispatch({ type: CLOSE_SUBSCRIBE_FORM_MODAL });

export const openChatbot = () => (dispatch) => dispatch({ type: OPEN_CHATBOT });
export const closeChatbot = () => (dispatch) =>
  dispatch({ type: CLOSE_CHATBOT });

export const chatbotOpen = () => (dispatch) =>
  dispatch({ type: CHATBOT_OPENED });
export const ChatbotClose = () => (dispatch) =>
  dispatch({ type: CHATBOT_CLOSED });

export const openFundingChatbot = () => (dispatch) =>
  dispatch({ type: OPEN_FUNDING_CHATBOT });
export const closeFundingChatbot = () => (dispatch) =>
  dispatch({ type: CLOSE_FUNDING_CHATBOT });

export const showAudioPlayer = () => (dispatch) =>
  dispatch({ type: SHOW_AUDIO_PLAYER });
export const hideAudioPlayer = () => (dispatch) =>
  dispatch({ type: HIDE_AUDIO_PLAYER });

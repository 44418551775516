

import { ORGANIMS_REQUEST_FAILED , ORGANIMS_REQUEST_SUCCESS , ORGANIMS_REQUEST_LOADING} from '../../constants/organisms';


const initialState = {
    loading: false,
    list : {},
    error : ''
}

export const organismsReducer = ( state = initialState , action ) => {
 

    switch (action.type) {
        case ORGANIMS_REQUEST_LOADING:
            return { ...state , loading: action.payload , list:[] }
        case ORGANIMS_REQUEST_SUCCESS:
            return { ...state , loading: false , list: action.payload }
        case ORGANIMS_REQUEST_FAILED:
            return { ...state , loading: false , list:[], error : action.payload }
        default:
            return state;
    }
}


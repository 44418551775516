import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "antd/dist/antd.min.css";
import "./styles/global.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//store provider
import { Provider } from "react-redux";

//store
import store from "./redux/store";
import AuthUser from "./components/AuthUser";

// !APPOLO CONFIG
const client = new ApolloClient({
  uri: `${"https://api.intaliq.ma/graphql"}`,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
  },
});

const rootElement = document.getElementById("root");

const Root = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <AuthUser>
        <App />
      </AuthUser>
    </ApolloProvider>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

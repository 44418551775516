import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

//!MIDDLEWARES
import thunk from "redux-thunk";
import logger from "redux-logger";

//!REDUCERS
import { postsReducer } from "./reducers/posts";
import sharedReducer from "./reducers/shared";
import { organismsReducer } from "./reducers/organisms";
import { rootCategoriesReducer } from "./reducers/rootCategories";
import { categoriesReducer } from "./reducers/categories";
import { conversationReducer } from "./reducers/conversation";
import { fundingConversationReducer } from "./reducers/fundingConversation";
import chatbotReducer from "./reducers/chatbot";
import { userReducer } from "./reducers/user";
import podcastsReducer from "./reducers/podcasts";

//!REDUCER
const reducer = combineReducers({
  posts: postsReducer,
  organisms: organismsReducer,
  shared: sharedReducer,
  rootCategories: rootCategoriesReducer,
  categories: categoriesReducer,
  conversation: conversationReducer,
  fundingConversation: fundingConversationReducer,
  chatbot: chatbotReducer,
  user: userReducer,
  podcasts: podcastsReducer,
});

//!APP INITIAL STATE
const initialState = {};

const middleware =
  process.env.NODE_ENV === "development" ? [thunk, logger] : [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

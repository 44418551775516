import { CATEGORIES_REQUEST_FAILED, CATEGORIES_REQUEST_LOADING, CATEGORIES_REQUEST_SUCCESS } from "../../constants/categories";

const initialState = {
    loading: false,
    error : '',
    items : []
}

export const categoriesReducer = ( state = initialState , action  ) => {

    switch (action.type) {
        case CATEGORIES_REQUEST_LOADING:
            return {
                ...state,
                loading : true
            }

        case CATEGORIES_REQUEST_SUCCESS:
            return {
                ...state,
                loading : false,
                items : action.payload
            }
        
        case CATEGORIES_REQUEST_FAILED:
            return {
                ...state,
                loading : false,
                error: action.payload
            }
    
        default:
           return state;
    }
}
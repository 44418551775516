import {useState} from 'react';
import {  ArrowDownOutlined } from '@ant-design/icons';
import { Button , Col, Modal, Row } from 'antd';
import Modalcard from './ModalCard';

import './Modal.css';
import { Link } from 'react-router-dom';



 

const ChatbotModal = ({ content , extraContent }) => {
    const [numberToShow, setNumberToShow] = useState(10);
    return (
        <div className="chatbot__modal bg-white">
            
            <Row gutter={ [10 , 10]}>
            {  content?.filter((x, index) => index < numberToShow)?.map((program, index) => (
                <Col xs={24} md={24} lg={content?.length > 5 ? 12 : 24} >
                    <Modalcard key={program.id} index={index} {...program} />
                    {program?.organims?.length > 0 && <div style={ { marginBottom : 20 }}>
                                <h2>Les organismes qui financent sont : </h2>
                        <div>
                            {program?.organims?.map((organism) => (
                                <p>
                                    <Link className = 'text-violet' to={`/organism/${organism?.slug}`}>{ organism?.title}</Link>
                                </p>
                            ) ) }
                        </div>
                    </div>}
                </Col>
            ) )}
            </Row>
            
            { (content.length > numberToShow ) && <Button shape="round" type = 'primary' style = {{color : '#555555'}} onClick = { () => setNumberToShow(content.length) } >Plus de résultat <ArrowDownOutlined />  </Button>}

        </div>
    )
}

export default ChatbotModal;

export const USER_LOADING = "USER_LOADING";
export const SET_USER_INFOS = "SET_USER_INFOS";
export const RESET_USER_INFOS = "RESET_USER_INFOS";
export const SET_USER_LOGGED_STATUS = "SET_USER_LOGGED_STATUS";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const IS_CHECKING_USER_AUTH = "IS_CHECKING_USER_AUTH";
export const UPDATE_CURRENT_POST = "UPDATE_CURRENT_POST";
export const UPDATE_ALL_POSTS = "UPDATE_ALL_POSTS";

export const UPDATE_CURRENT_USER_PODCAST = "UPDATE_CURRENT_USER_PODCAST";
export const UPDATE_ALL_USER_PODCASTS = "UPDATE_ALL_USER_PODCASTS";
export const REMOVE_USER_PODCAST = "REMOVE_USER_PODCAST";

export const UPDATE_CURRENT_USER_PROGRAM = "UPDATE_CURRENT_USER_PROGRAM";
export const UPDATE_ALL_USER_PROGRAMS = "UPDATE_ALL_USER_PROGRAMS";
export const REMOVE_USER_PROGRAM = "REMOVE_USER_PROGRAM";

export const SET_CURRENT_PODCAST_MESSAGE = "SET_CURRENT_PODCAST_MESSAGE";
export const RESET_CURRENT_PODCAST_MESSAGE = "RESET_CURRENT_PODCAST_MESSAGE";


import {
    PODCAST_VOLUME_OFF,
    PODCAST_VOLUME_ON,
    PODCAST_PAUSE,
    PODCAST_PLAY,
    UPDATE_CURRENT_PODCAST,
    UPDATE_ALL_PODCASTS,
    RESET_PODCASTS
} from '../../constants/podcasts';

const INITIAL_STATE = {
    loading : false,
    playing : false,
    muted : false,
    error : '',
    current : {},
    all : [
        // {
        //     id : 1,
        //     author : 'Mr Ahmed & Mr. Mehdi',
        //     title : 'Lorem ipsum',
        //     imageUrl : require('../../../assets/images/podcasts/pod.svg').default,
        //     songUrl : 'https://intaliq.ma/cockpit/wp-content/uploads/2021/04/bensound-happiness.mp3'
        // }
    ]
}


const podcastsReducer = ( state = INITIAL_STATE , action ) => {
    
    switch (action.type) {
        case RESET_PODCASTS : 
            return {
                ...state,
                ...INITIAL_STATE
            }
        case PODCAST_PLAY:
            return {
                ...state,
                playing : true
            }
        case PODCAST_PAUSE : 
            return {
                ...state,
                playing : false
            }
        case PODCAST_VOLUME_ON : 
            return {
                ...state,
                muted : false
            }
        case PODCAST_VOLUME_OFF : 
            return {
                ...state,
                muted : true
            }
        case UPDATE_CURRENT_PODCAST : 
            return {
                ...state,
                current : action.payload
            }
        case UPDATE_ALL_PODCASTS : 
            return {
                ...state,
                all : action.payload
            }
        default:
            return state;
    }
}

export default podcastsReducer;
import { Tabs } from "antd";
import Cards from "../Cards";
import ChatbotModal from "../Modal";

import "./tabs.css";

const { TabPane } = Tabs;

const ChatbotTabs = ({ content }) => {
  console.log(content);

  const handleChange = () => {
    console.log("tabs");
  };

  const getTabTitle = (type) => {
    console.log("type", content);

    switch (type?.toLowerCase()) {
      case "post":
        return "Wikintaliq";
      case "blog":
        return "Blog Intaliq";
      case "organism":
        return "Organismes";
      case "program":
        return "Programmes";
      case "podcasts":
        return "Podcasts";
      case "faq":
        return "FAQ";
      default:
        return type;
    }
  };

  return (
    <div className="chatbot__tabs">
      <Tabs onChange={handleChange} type="card">
        {content?.map((x, index) => (
          <TabPane
            tab={getTabTitle(x?.type)}
            key={index + 1}
            // style={{
            //   width: "1800px !important",
            // }}
          >
            {x?.type?.toLowerCase() !== "program" && (
              <Cards cards={x?.content} cardType={x?.type} />
            )}

            {x?.type?.toLowerCase() === "program" && (
              <ChatbotModal content={x.content} />
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ChatbotTabs;

//! TODO : import actions from
import { CHATBOT_LOADING , 
        UPDATE_CURRENT_PROCESS , 
        UPDATE_CHATBOT_REPLY , 
        CHATBOT_ERROR , 
        RESET_CHATBOT , 
        UPDATE_CHATBOT_CONVERSATION,
        RESET_REPLY,
        CHATBOT_UPDATE_USER_CHOICE,
        UPDATE_HEADER_BUTTONS,
        SET_PREVIOUS_ACTIONS
} from '../../constants/chatbot';

const INITIAL_STATE = {
    loading : false,
    error : '',
    process : '',
    processEnd : false,
    reply : {},
    messages : [],
    user : {
        id : '',
        choice : {}
    },
    header : {
        buttons : {
            loading : true,
            data : [],
        }
    },
    previousActions : []
}

const chatbotReducer = ( state = INITIAL_STATE , action ) => {
    switch (action.type) {
        case CHATBOT_LOADING:
            return {
                ...state,
                loading : true
            }
        case RESET_REPLY:
            return {
                ...state,
                reply : {
                    ...state.reply,
                    type : ''
                }
            }

        case UPDATE_CURRENT_PROCESS:
            return {
                ...state,
                process : action.payload
            }
        case UPDATE_HEADER_BUTTONS:
            return {
                ...state,
                header : {
                    ...state.header,
                    buttons : action.payload
                }
            }
        case CHATBOT_UPDATE_USER_CHOICE:
            return {
                ...state,
                user : {
                    ...state.user, 
                    choice : {
                        ...state.user.choice,
                        ...action.payload
                    }
                }
            }
            
        case CHATBOT_ERROR:
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case UPDATE_CHATBOT_CONVERSATION:
            return {
                ...state,
                loading : false,
                messages : [
                    ...state.messages,
                    {...action.payload}
                ]
            }

        case UPDATE_CHATBOT_REPLY:
            return {
                ...state,
                loading : false,
                reply : {
                    ...action.payload
                }
            }
    

        case RESET_CHATBOT: 
            return {
                ...state,
                ...INITIAL_STATE
            };

        case SET_PREVIOUS_ACTIONS : 
            return {
                ...state,
                previousActions : action?.payload
            }
                
        default:
            return state;
    }
}

export default chatbotReducer;

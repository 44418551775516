export const NEXT_PODCAST = 'NEXT_PODCAST';
export const PREVIOUS_PODCAST = 'PREVIOUS_PODCAST';
export const PODCAST_PAUSE = 'PODCAST_PAUSE';
export const PODCAST_PLAY = 'PODCAST_PLAY';
export const PODCAST_VOLUME_OFF = 'PODCAST_VOLUME_OFF';
export const PODCAST_VOLUME_ON = 'PODCAST_VOLUME_ON';
export const UPDATE_CURRENT_PODCAST = 'UPDATE_CURRENT_PODCAST';
export const UPDATE_ALL_PODCASTS = 'UPDATE_ALL_PODCASTS';
export const UPDATE_CURRENT_INDEX = 'UPDATE_CURRENT_INDEX';
export const RESET_PODCASTS = 'RESET_PODCASTS';


import React from 'react'
import ChatbotTabs from '../ChatbotBots'
import ChatbotModal from '../Modal'

const ModalTabs = ({ content = [] }) => {
    console.log('content' , content)
    return (
        <div className = 'modal-tabs'>
             <ChatbotTabs  content={   
                content?.map((item) => ({
                    title: item?.title,
                    body: <ChatbotModal
                        content={item?.content}
                    //     extraContent={
                    //              <div>
                    //             <h2>Les organismes qui financent sont : </h2>
                    //             { console.log(item?.content?.organims)}
                    //     <div>
                    //         {item?.organisms?.map((organism) => (
                    //             <p>{ organism?.title}</p>
                    //         ) ) }
                    //     </div>
                    // </div>
                    //     }
                    
                    />,
                    
                }
                ) )
            }  />
           
        </div>
    )
}

export default ModalTabs

export const CHATBOT_LOADING = "CHABOT_LOADING";
export const CHATBOT_ERROR = "CHABOT_ERROR";
export const RESET_CHATBOT = "RESET_CHATBOT";
export const UPDATE_CHATBOT_CONVERSATION = "UPDATE_CHATBOT_CONVERSATION";
export const UPDATE_CHATBOT_REPLY = "UPDATE_CHATBOT_REPLY";
export const UPDATE_CURRENT_PROCESS = "UPDATE_CURRENT_PROCESS";
export const RESET_REPLY = "RESET_REPLY";
export const CHATBOT_UPDATE_USER_CHOICE = "CHATBOT_UPDATE_USER_CHOICE";
export const UPDATE_HEADER_BUTTONS = "UPDATE_HEADER_BUTTONS";
export const SET_PREVIOUS_ACTIONS = "SET_PREVIOUS_ACTIONS";

import  { useState } from 'react';
import {  ArrowRightOutlined } from '@ant-design/icons';
import { Button , Modal, Row } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

//!ASSETS
import contractorIconViolet from '../../../../../../assets/icons/contractorViolet.svg';
import servicesIconViolet from '../../../../../../assets/icons/servicesViolet.svg';

import './Modalcard.css';
import { addProgramToUser, removeProgramFromUser } from '../../../../../../redux/actions/user';


const Modalcard = ({ id , title , content , index , program_infos , slug }) => {

    const [ showModal , setShaowModal ] = useState(false);

    const { t } = useTranslation();

    const { current, all } = useSelector(state => state.user.programms);
    
    const dispatch = useDispatch();

    const alredyExists = all?.some(program => program.id === id);

    // console.log(content);


    return (
        <div key = {id} className="program-card">
            <h4 className="title text-uppercase text-violet"> {index+1}. {title} </h4>
            <div className="content" dangerouslySetInnerHTML={{__html: content}}/>
            <Button  type = 'primary' style = {{color : '#555555'}} onClick = { () => setShaowModal(true) } >{t('EnSavoirPlus')} <ArrowRightOutlined /> </Button>
            <Modal
                wrapClassName = 'chatbot__modalcard'
                title={
                    <Row justify="space-between" style = {{ padding: "0 20px" }}>
                        <h4 className='title text-uppercase text-violet m-0'>{title}</h4>
                    </Row>
                }
            
                width = {700}
                okText   = 'Fermer'
                cancelText   = 'Fermer'
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps = {{ghost : true , style : { color : '#7E00D3' } }}
                visible={showModal}
                onOk={() => setShaowModal(false)}
                onCancel={() => setShaowModal(false)}
                bodyStyle={{ maxHeight: '70vh', overflow: 'scroll' }}
                    footer={
                        <Row justify='space-between'>
                            {!alredyExists &&
                                <Button
                                    onClick={() => dispatch(addProgramToUser({
                                        id, title , slug
                                    }))}                                
                                    loading={current?.loading && id === current?.id} type='primary' ghost style={{ color: '#fff' }}  >
                                    Ajouter aux favoris
                                </Button>
                            }

                               {alredyExists &&
                                <Button
                                onClick={() => dispatch(removeProgramFromUser({
                                    id, title , slug
                                })) }
                                    loading={current?.loading && id === current?.id} type='primary' danger style={{ color: '#fff' }}   >
                                    Retirer des favoris
                                </Button>
                            }
         
                        <Button type = 'primary' ghost style = {{color : '#fff'}} onClick = { () => setShaowModal(false) } >{t('Fermer')} </Button>
                    </Row>

                }
            >

                {
                    program_infos?.contractors?.length > 0 && program_infos?.services?.length > 0 &&     <div className="content__header">
                        
                        {
                            program_infos?.contractors?.length > 0 && <div className="sidebar-block">
                                <div className="sidebar-block__head">
                                    <img src={contractorIconViolet} alt=""/>
                                    <h4 className="title">{t('EntrepreneursConcernes')}</h4>
                                </div>

                                <div className="sidebar-block__content">
                                    {
                                        program_infos?.contractors?.map( ({contractor}) => (
                                            <p key = {contractor}> { contractor } </p>
                                        ) )
                                    }
                                </div>
                            </div> 
                        }
                        
                        {
                            program_infos?.services?.length &&  <div className="sidebar-block">
                                <div className="sidebar-block__head">
                                    <img src={servicesIconViolet} alt=""/>
                                    <h4 className="title">{t('ServicesProposes')}</h4>
                                </div>

                                <div className="sidebar-block__content">
                                    {
                                        program_infos?.services?.map( ({service}) => (
                                            <p key = {service}> { service } </p>
                                        ) )
                                    }
                                </div>
                            </div>
                        }

                       
                </div>
               
               
                }

            
                <div className="content" dangerouslySetInnerHTML = {{__html : content }} />
               
            </Modal>
        </div>
    )
}

export default Modalcard

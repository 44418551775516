import { motion } from "framer-motion";
import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppContainer from "../AppContainer";
import { Button, Popover, Popconfirm, Typography } from "antd";
import { Slider, Row, Space } from "antd";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";

import { ReactComponent as VolumeIcon } from "../../../assets/icons/volume.svg";
import { ReactComponent as RedoIcon } from "../../../assets/icons/redo.svg";
import { ReactComponent as StopIcon } from "../../../assets/icons/stop-svgrepo-com.svg";

import "./AppAudioPlayer.css";
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  PlayCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
import {
  pausePodcast,
  playPodcast,
  turnOffVolume,
  turnOnVolume,
  updateCurrentPordcat,
} from "../../../redux/actions/podcasts";
import { hideAudioPlayer } from "../../../redux/actions/shared";

const { Paragraph } = Typography;

const AppAudioPlayer = () => {
  const dispatch = useDispatch();
  const { isAudioPlayerVisible } = useSelector((state) => state.shared);
  const {
    current: currentPodcast,
    all: allPodcasts,
    playing,
    muted,
  } = useSelector((state) => state.podcasts);
  const { currentLang } = useSelector((state) => state.shared);
  const audio = useRef(null);
  const [currentTime, setCurrentTime] = useState("00 : 00");
  const [musicDuration, setMusicDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [repeat, setRepeat] = useState(false);

  const audioContainer = {
    visible: { opacity: 1, bottom: "0px" },
    hidden: { opacity: 0, bottom: "-80px" },
    transition: { duration: 1, ease: "linear" },
  };

  const formatTime = useCallback(
    (seconds) => {
      if (typeof seconds === "number") {
        const time = moment.utc(seconds * 1000).format("HH:mm:ss");
        const timeArray = time.split(":");
        const hours = timeArray.shift();
        return hours === "00" ? timeArray.join(" : ") : time;
      }
      return currentTime;
    },
    [currentTime]
  );

  const handleMusicDuration = useCallback((e) => {
    setMusicDuration(e.currentTarget.duration);
  }, []);

  const handleTimeUpdate = useCallback(
    (e) => {
      setCurrentTime(formatTime(e.currentTarget.currentTime));
      setProgress((e.currentTarget.currentTime / musicDuration) * 100);
    },
    [formatTime, musicDuration]
  );

  const handleNextClick = useCallback(() => {
    const currentIndex = allPodcasts?.findIndex(
      (podcast) => podcast?.id === currentPodcast?.id
    );
    if (currentIndex === allPodcasts.length - 1) {
      dispatch(updateCurrentPordcat(allPodcasts[0]));
    } else {
      dispatch(updateCurrentPordcat(allPodcasts[currentIndex + 1]));
    }
  }, [allPodcasts, currentPodcast?.id, dispatch]);

  const handleMusicEnded = useCallback(
    (e) => {
      const currentAudio = e.currentTarget;
      if (repeat) {
        currentAudio.currentTime = 0;
        currentAudio.play();
      }

      if (!repeat) {
        handleNextClick();
      }
    },
    [repeat, handleNextClick]
  );

  useEffect(() => {
    const currentAudio = audio.current;
    currentAudio.addEventListener("loadedmetadata", handleMusicDuration);
    currentAudio.addEventListener("timeupdate", handleTimeUpdate);
    currentAudio.addEventListener("ended", handleMusicEnded);

    if (playing) {
      currentAudio.play();
    }

    if (!playing) {
      currentAudio.pause();
    }

    // currentAudio.addEventListener('canplay' , handleCanPlay)
    return () => {
      currentAudio.removeEventListener("loadedmetadata", handleMusicDuration);
      currentAudio.removeEventListener("timeupdate", handleTimeUpdate);
      currentAudio.removeEventListener("ended", handleMusicEnded);
    };
  }, [handleMusicDuration, handleTimeUpdate, handleMusicEnded, playing]);

  const togglePlay = () => {
    // setPlaying( prev => !prev )
    playing ? dispatch(pausePodcast()) : dispatch(playPodcast());
  };

  const handleSliderChange = (value) => {
    const time = (value * musicDuration) / 100;
    const currentAudio = audio.current;
    currentAudio.currentTime = time;
    setProgress(value);
    setCurrentTime(formatTime(value));
  };

  const handleFormatTime = (value) => {
    const time = (value * musicDuration) / 100;
    return formatTime(time);
  };

  const handleMuted = () => {
    const currentAudio = audio.current;
    currentAudio.muted = !muted;
    // setMuted(prev => !prev)
    muted ? dispatch(turnOnVolume()) : dispatch(turnOffVolume());
  };

  const handleVolumeChange = (value) => {
    const currentAudio = audio.current;
    currentAudio.volume = value / 100;
  };

  const handlePreviousClick = () => {
    const currentAudio = audio.current;
    // console.log(currentAudio.currentTime)
    const currentIndex = allPodcasts?.findIndex(
      (podcast) => podcast?.id === currentPodcast?.id
    );

    if (currentAudio.currentTime > 3) {
      currentAudio.currentTime = 0;
      currentAudio.play();
      return;
    }

    if (currentIndex === allPodcasts.length - 1) {
      dispatch(updateCurrentPordcat(allPodcasts[0]));
    }

    if (currentIndex === 0) {
      dispatch(updateCurrentPordcat(allPodcasts[allPodcasts.length - 1]));
    }

    if (currentIndex !== 0) {
      dispatch(updateCurrentPordcat(allPodcasts[currentIndex - 1]));
    }
  };

  const handleStopPlayingMusic = () => {
    const currentAudio = audio.current;
    currentAudio.currentTime = 0;
    dispatch(pausePodcast());
    dispatch(hideAudioPlayer());
  };

  const content = (
    <Slider
      className='custom-bg'
      defaultValue={20}
      max={100}
      onChange={handleVolumeChange}
      disabled={muted}
      tipFormatter={(value) => `${value} %`}
    />
  );

  return (
    <motion.div
      className='app__audioPlayer-container'
      initial='hidden'
      variants={audioContainer}
      animate={`${isAudioPlayerVisible ? "visible" : "hidden"}`}>
      <Slider
        tipFormatter={handleFormatTime}
        defaultValue={progress}
        onChange={handleSliderChange}
        step={0.01}
        max={100}
        value={progress}
      />

      <AppContainer>
        <h3 className="title__mobile">{currentPodcast?.title}</h3>
        {/* <Audio onListen = { (e) => console.log(e) } /> */}
        <Row className='music-player-container '>
          <Space className='music-infos  align-items-center hide-on-mobile'>
            <div className='img-wrapper'>
              <img src={currentPodcast?.imageUrl} alt='' />
            </div>
            <div className='text-infos'>
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: false,
                  onExpand: () => console.log("onExpand"),
                }}>
                {currentPodcast?.title}
              </Paragraph>
              <p>{currentPodcast?.author || currentPodcast?.authorName}</p>
            </div>
          </Space>

          <audio
            ref={audio}
            controls
            className='d-none'
            src={currentPodcast?.songUrl}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <div className='music-playing-items  d-flex align-items-center justify-content-center'>
            <Button
              className='__custom_btn'
              type='text'
              onClick={handlePreviousClick}>
              <StepBackwardOutlined
                style={{
                  transform: `${currentLang === "ar" ? "rotate(180deg)" : ""}`,
                }}
              />
            </Button>

            <Button className='__custom_btn' type='text' onClick={togglePlay}>
              {playing && (
                <PauseCircleFilled
                  style={{
                    transform: `${currentLang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                />
              )}
              {!playing && (
                <PlayCircleFilled
                  style={{
                    transform: `${currentLang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                />
              )}
            </Button>

            <Button
              className='__custom_btn'
              type='text'
              onClick={handleNextClick}>
              <StepForwardOutlined
                style={{
                  transform: `${currentLang === "ar" ? "rotate(180deg)" : ""}`,
                }}
              />
            </Button>

            <Popconfirm
              title='Voulez-vous vraiment arréter ? '
              onConfirm={handleStopPlayingMusic}
              onCancel={() => console.log("cancel")}
              okText='Oui'
              cancelText='Non'>
              <Button className='__custom_btn' type='text'>
                <StopIcon style={{ width: "20px" }} />
              </Button>
            </Popconfirm>
          </div>
          <div className='music-features d-flex align-items-center  justify-content-end'>
            <div className='music-time d-flex align-items-center'>
              {/* <Button className="__custom_btn __custom_position" type="text" onClick = { () => setRepeat(prev => !prev) } >
                                <RedoIcon className = {`${repeat ? 'active' : ''}`} />
                            </Button> */}
              <Row className='d-flex _is_mobile_dsp__none'>
                <span>{currentTime}</span>
                <span> / </span>
                <span>{formatTime(musicDuration)}</span>
              </Row>
            </div>
            <div className='music-volume'>
              <Popover content={content} title='Volume'>
                <Button
                  className='_is_mobile_dsp__none'
                  type='text'
                  onClick={handleMuted}>
                  <VolumeIcon
                    className={`${!muted ? "active" : ""}`}
                    style={{
                      transform: `${currentLang === "ar" ? "rotate(180deg)" : ""
                        }`,
                    }}
                  />
                </Button>
              </Popover>
            </div>
            <div>
              <Popconfirm
                title='Voulez-vous vraiment arrêter ? '
                onConfirm={handleStopPlayingMusic}
                onCancel={() => console.log("cancel")}
                okText='Oui'
                cancelText='Non'>
                <Button type='text'>
                  <CloseCircleOutlined />
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Row>
      </AppContainer>
    </motion.div>
  );
};

export default AppAudioPlayer;

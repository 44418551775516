export const SET_CURRENT_URL = "SET_CURRENT_URL";
export const SET_PAGE_INFOS = "SET_PAGE_INFOS";
export const OPEN_MENU = "OPEN_MENU";
export const OPEN_MENUV2 = "OPEN_MENUV2";
export const OPEN_MODAL_CHATBOT = "OPEN_MODAL_CHATBOT";
export const CLOSE_MENU = "CLOSE_MENU";
export const CLOSE_MENUV2 = "CLOSE_MENUV2";
export const OPEN_CHATBOT = "OPEN_CHATBOT";
export const OPEN_FUNDING_CHATBOT = "OPEN_FUNDING_CHATBOT";
export const OPEN_HEADER_VIDEO = "OPEN_HEADER_VIDEO";
export const CLOSE_FUNDING_CHATBOT = "CLOSE_FUNDING_CHATBOT";
export const CLOSE_CHATBOT = "CLOSE_CHATBOT";
export const CLOSE_HEADER_VIDEO = "CLOSE_HEADER_VIDEO";
export const CLOSE_MODAL_CHATBOT = "CLOSE_MODAL_CHATBOT";
export const SET_APP_PREVIOUS_LINKS = "SET_APP_PREVIOUS_LINKS";
export const SET_APP_CURRENT_LINK = "SET_APP_CURRENT_LINK";
export const RESET_APP_BREADCRUMB = "RESET_APP_BREADCRUMB";
export const CHATBOT_OPENED = "CHATBOT_OPENED";
export const CHATBOT_CLOSED = "CHATBOT_CLOSED";
export const OPEN_SUBSCRIBE_FORM_MODAL = "OPEN_SUBSCRIBE_FORM_MODAL";
export const CLOSE_SUBSCRIBE_FORM_MODAL = "CLOSE_SUBSCRIBE_FORM_MODAL";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const CLOSE_MESSAGE = "CLOSE_MESSAGE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const CHANGE_CURRENT_LANGUAGE = "CHANGE_CURRENT_LANGUAGE";
export const SHOW_AUDIO_PLAYER = "SHOW_AUDIO_PLAYER";
export const HIDE_AUDIO_PLAYER = "HIDE_AUDIO_PLAYER";
export const UPDATE_URL_STATE = "UPDATE_URL_STATE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CURRENT_FR_URL = "SET_CURRENT_FR_URL";
export const SET_CURRENT_AR_URL = "SET_CURRENT_AR_URL";

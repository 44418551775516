import { useEffect } from 'react'
import { Spin } from 'antd';

import logoSrc from '../../../assets/images/logo/logo-intaliq-updated.svg';


//!STYLE
import './BackDropLoader.css'

const BackDropLoader = () => {

    useEffect(() => {
        document.querySelector('body').classList.add('overflow-hidden')

        return () => {
            document.querySelector('body').classList.remove('overflow-hidden')
        }
    } , [])

    return (
        <div className = 'app__backdrop' >
            <img src={logoSrc} alt=""/>
            <div className = 'circle-pulse'></div>
        </div>
    )
}

export default BackDropLoader;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//!TRANSLATIONS
import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_FR } from './fr';
import { TRANSLATIONS_AR } from './ar';



const resources = {
  ar: {
    translation: TRANSLATIONS_AR
  },
  en: {
    translation: TRANSLATIONS_EN
  },
  fr: {
    translation: TRANSLATIONS_FR
  },
  
  lng: "fr",
  fallbackLng: "fr",
};

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources
});

export default i18n;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { authenticateUser } from '../../redux/actions/user';
import Loader from '../Library/BackdropLoader'

const AuthUser = ({ children }) => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(authenticateUser());
    }, [dispatch]);

    return (
        <>
            {children}
        </>
    )
}

export default AuthUser;

//  
import './Button.css';

const Button = ({ text, click }) => {
    const handleClick = () => {
        if (click) {
            click();
        }
        
    }
    return (
        <button className = 'chatbot__btn' onClick = { handleClick } >
            {text}
        </button>
    )
}

export default Button;

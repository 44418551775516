import { POSTS_REQUEST_FAILED , POSTS_REQUEST_SUCCESS  , POSTS_REQUEST_LOADING} from '../../constants/posts';


const initialState = {
    loading: false,
    list : [],
    error : ''
}

export const postsReducer = ( state = initialState , action ) => {
 

    switch (action.type) {
        case POSTS_REQUEST_LOADING:
            return { ...state , loading: true , list:[] }
        case POSTS_REQUEST_SUCCESS:
            return { ...state , loading: false , list: action.payload }
        case POSTS_REQUEST_FAILED:
            return { ...state , loading: false , list:[], error : action.payload }
        default:
            return state;
    }
}


import { Tabs } from "antd";
import "./tabs.css";


const { TabPane } = Tabs;

const ChatbotTabs = ({ content }) => {
  return (
    <div className='chatbot__tabs hello'>
      <Tabs type='card'>
        {content?.map((x, index) => (
          <TabPane closable={false} tab={x?.title} key={index + 1}>
            {x?.body}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default ChatbotTabs;

export const WP_SOURCE_BASE_URL = "https://intaliq.ma/cockpit";
export const API_URL = `${
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://intaliq.herokuapp.com"
}`;
export const CHATBOT_API_URL = `${
  process.env.NODE_ENV === "development"
    ? "https://api-chatbot.onrender.com" || "http://localhost:9000"
    : "https://api-chatbot.onrender.com"
}`;

export const PROD_URL = "https://intaliq.ma";

export const FUNDING_CHATBOT_API_URL = `${
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://intaliq-v2.herokuapp.com"
}`;

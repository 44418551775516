const PODCASTS_TRANSLATIONS_EN = {
    podcastsPageTitle: 'Intaliq podcasts',
    podcastsPageDescription: 'Find all the news and inspiration podcasts.',
    podcastsPageContentTitle: 'Program schedule',
    searchPodcastText: 'Find a podcast'
}

const HOMEPAGE_TRANSLATIONS_EN = {
    homeTitle: `The entrepreneur's platform`,
    homeDescription: `You deserve to have all the resources you need to succeed in your project.`,
    firstBlockText: `operational guides`,
    secondBlockText: `sources of funding`,
    thirdBlockText: `video and podcasts per week`
}

const GENERAL_TRANSLATIONS_EN = {
    goToDateText: 'Go to this date',
    listenText: 'Listen',
    monthText: 'Month',
    yearText: 'Year',
    addToMyWishlist: 'Add to my wishlist',
    mostListened: 'Most listened',
    popularThemes: 'The most popular themes',
    themes: 'Themes',
    other: 'Other',
    login: 'login',
    discoverIntaliq: 'Intaliq in 2 minutes',
    MyProfile: 'MProfile',
    MyPodcasts: 'Podcasts',
    MyReadingList: 'My reading list',
    logout: 'Log out'
}

export const TRANSLATIONS_EN = {
    ...PODCASTS_TRANSLATIONS_EN,
    ...GENERAL_TRANSLATIONS_EN,
    ...HOMEPAGE_TRANSLATIONS_EN
}
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyA9KN0QSxPbRzGaNxUphzcPBBOSstqflxE",
  authDomain: "cgem-6e73f.firebaseapp.com",
  projectId: "cgem-6e73f",
  storageBucket: "cgem-6e73f.appspot.com",
  messagingSenderId: "849121314322",
  appId: "1:849121314322:web:f3540e8878566de312bacb",
  measurementId: "G-RXEDKW00ST",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

//firebase.analytics();

export default firebaseApp;

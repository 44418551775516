import axios from "axios";
import { CHATBOT_API_URL } from "../../../config/source";

import {
  CHATBOT_LOADING,
  UPDATE_CHATBOT_REPLY,
  CHATBOT_ERROR,
  RESET_CHATBOT,
  UPDATE_CHATBOT_CONVERSATION,
  UPDATE_CURRENT_PROCESS,
  CHATBOT_UPDATE_USER_CHOICE,
  UPDATE_HEADER_BUTTONS,
  SET_PREVIOUS_ACTIONS,
} from "../../constants/chatbot";
import store from "../../store";

export const makePreviousAction = () => (dispatch, getState) => {
  const {
    chatbot: { previousActions },
  } = getState();
  // const newArray = [...previousActions?.filter( ( _ , i) => i < previousActions?.length - 1 )];

  if (previousActions?.length > 1) {
    const { eventType, payload } = previousActions?.pop();

    const userMsg = {
      id: Math.random(),
      isChatbot: false,
      time: `${new Date().getHours()} : ${new Date().getMinutes()}`,
      type: "text",
      content: {
        text: "...",
      },
    };

    store.dispatch(updateConversation({ ...userMsg }));

    if (eventType === "event") {
      store.dispatch(
        triggerEvent({
          event: payload,
          previousAction: true,
        })
      );
    }

    if (eventType === "message") {
      store.dispatch(
        sendMessage({
          event: payload,
          previousAction: true,
        })
      );
    }
  } else {
    const userMsg = {
      id: Math.random(),
      isChatbot: false,
      time: `${new Date().getHours()} : ${new Date().getMinutes()}`,
      type: "text",
      content: {
        text: "...",
      },
    };

    store.dispatch(updateConversation({ ...userMsg }));

    store.dispatch(
      triggerEvent({
        event: "start",
        previousAction: true,
      })
    );

    dispatch({
      type: SET_PREVIOUS_ACTIONS,
      payload: [
        {
          eventType: "event",
          payload: "start",
        },
      ],
    });
  }
};

export const triggerEvent =
  ({ event, previousAction = false }) =>
  async (dispatch, getState) => {
    const currentState = getState();
    // console.log("currentState", currentState);
    dispatch({ type: CHATBOT_LOADING });

    try {
      if (event === "start") {
        //when start we trigger 2 event welcome and the starting point
        const { data: welcomeData } = await axios.post(
          `${CHATBOT_API_URL}/api/v1/chatbot/event`,
          { event: "welcome" }
        );

        if (welcomeData?.success) {
          const previousActions = currentState?.chatbot?.previousActions;
          if (!previousAction) {
            dispatch({
              type: SET_PREVIOUS_ACTIONS,
              payload: [
                ...currentState?.chatbot?.previousActions,
                {
                  eventType: "event",
                  payload: event,
                },
              ],
            });
          } else {
            const updatedPreviousActions = [
              ...previousActions?.filter(
                (_, index) => index < previousActions?.length - 1
              ),
            ];
            dispatch({
              type: SET_PREVIOUS_ACTIONS,
              payload: updatedPreviousActions,
            });
          }
          const message = {
            id: 1,
            isChatbot: true,
            ...welcomeData?.result,
          };

          dispatch({ type: UPDATE_CHATBOT_CONVERSATION, payload: message });
          dispatch({ type: CHATBOT_LOADING });

          const { data: startData } = await axios.post(
            `${CHATBOT_API_URL}/api/v1/chatbot/event`,
            { event }
          );

          if (startData?.success) {
            dispatch({
              type: UPDATE_CHATBOT_REPLY,
              payload: startData?.result,
            });
            dispatch({
              type: UPDATE_HEADER_BUTTONS,
              payload: {
                loading: false,
                data: startData?.result.content,
              },
            });
          } else {
            dispatch({
              type: UPDATE_HEADER_BUTTONS,
              payload: {
                loading: false,
                data: [],
              },
            });
            throw new Error(
              welcomeData?.message ||
                "i am sorry , Something went wrong in my system..."
            );
          }
        } else {
          throw new Error(
            welcomeData?.message ||
              "i am sorry , Something went wrong in my system..."
          );
        }

        return;
      }

      const { data } = await axios.post(
        `${CHATBOT_API_URL}/api/v1/chatbot/event`,
        { event }
      );

      //console.log(data)

      if (data?.success) {
        if (!previousAction) {
          dispatch({
            type: SET_PREVIOUS_ACTIONS,
            payload: [
              ...currentState?.chatbot?.previousActions,
              {
                eventType: "event",
                payload: event,
              },
            ],
          });
        }

        if (data?.result?.responseType === "message") {
          dispatch({
            type: UPDATE_CHATBOT_CONVERSATION,
            payload: { ...data?.result, isChatbot: true },
          });
        }

        if (data?.result?.responseType === "reply") {
          dispatch({ type: UPDATE_CHATBOT_REPLY, payload: data?.result });
        }
      } else {
        throw new Error(
          data?.message || "i am sorry , Something went wrong in my system..."
        );
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: CHATBOT_ERROR, payload: err?.message });
    }
  };

export const sendMessage =
  ({ message }) =>
  async (dispatch) => {
    dispatch({ type: CHATBOT_LOADING });
    console.log(message);

    try {
      const { data } = await axios.post(
        `${CHATBOT_API_URL}/api/v1/chatbot/message`,
        { message }
      );

      if (data?.success) {
        console.log("data?.responseType", data);

        if (data?.result?.responseType === "reply") {
          dispatch({ type: UPDATE_CHATBOT_REPLY, payload: data?.result });
        }

        if (data?.result?.responseType === "message") {
          dispatch({
            type: UPDATE_CHATBOT_CONVERSATION,
            payload: data?.result,
          });
        }

        return;
      }

      throw new Error(
        data?.message || "i am sorry , Something went wrong in my system..."
      );
    } catch (error) {
      console.error(error);
      dispatch({ type: CHATBOT_ERROR, payload: error?.message });
    }
  };

export const searchContent =
  ({ content }) =>
  async (dispatch) => {
    dispatch({ type: CHATBOT_LOADING });

    try {
      const { data } = await axios.post(
        `${CHATBOT_API_URL}/api/v1/chatbot/search`,
        { ...content }
      );

      if (data?.success) {
        //console.log('data?.responseType' , data)

        if (data?.result?.responseType === "reply") {
          dispatch({ type: UPDATE_CHATBOT_REPLY, payload: data?.result });
        }

        if (data?.result?.responseType === "message") {
          dispatch({
            type: UPDATE_CHATBOT_CONVERSATION,
            payload: { ...data?.result, isChatbot: true },
          });
        }

        if (data?.result?.satisfiedMessage) {
          dispatch({ type: CHATBOT_LOADING });
          const { data: satisfiedData } = await axios.post(
            `${CHATBOT_API_URL}/api/v1/chatbot/event`,
            { event: "otherActionFromUser" }
          );

          if (satisfiedData?.success) {
            dispatch({
              type: UPDATE_CHATBOT_REPLY,
              payload: satisfiedData?.result,
            });
          }
        }

        //triggerEvent({event : 'satisfied'})
        //console.log(content);
        return;
      }

      throw new Error(
        data?.message || "i am sorry , Something went wrong in my system..."
      );
    } catch (error) {
      console.error(error);
      dispatch({ type: CHATBOT_ERROR, payload: error?.message });
    }
  };

export const getUserPrograms =
  ({ choice }) =>
  async (dispatch) => {
    dispatch({ type: CHATBOT_LOADING });

    try {
      const { data } = await axios.post(
        `${CHATBOT_API_URL}/api/v1/chatbot/search/programs`,
        { infos: choice }
      );

      if (data?.success) {
        if (data?.result?.responseType === "message") {
          if (data?.result?.title) {
            const botMsg = {
              id: 1,
              isChatbot: true,
              time: `${new Date().getHours()} : ${new Date().getMinutes()}`,
              type: "text",
              content: {
                text: data?.result?.title,
              },
            };
            dispatch({
              type: UPDATE_CHATBOT_CONVERSATION,
              payload: { ...botMsg },
            });
          }

          dispatch({
            type: UPDATE_CHATBOT_CONVERSATION,
            payload: { ...data?.result, isChatbot: true },
          });
        }

        if (data?.result?.responseType === "reply") {
          dispatch({ type: UPDATE_CHATBOT_REPLY, payload: data?.result });
        }
        dispatch({ type: CHATBOT_LOADING });
        const { data: satisfiedData } = await axios.post(
          `${CHATBOT_API_URL}/api/v1/chatbot/event`,
          { event: "otherActionFromUser" }
        );

        if (satisfiedData?.success) {
          dispatch({
            type: UPDATE_CHATBOT_REPLY,
            payload: satisfiedData?.result,
          });
        }

        // if (data?.result?.satisfiedMessage) {

        // } else {
        //   dispatch({ type: CHATBOT_LOADING });
        //   const { data: tryAgainData } = await axios.post(
        //     `${CHATBOT_API_URL}/api/v1/chatbot/event`,
        //     { event: "tryAgain" }
        //   );
        //   if (tryAgainData?.success) {
        //     if (tryAgainData?.result?.responseType === "message") {
        //       dispatch({
        //         type: UPDATE_CHATBOT_CONVERSATION,
        //         payload: { ...tryAgainData?.result, isChatbot: true },
        //       });
        //     }

        //     if (tryAgainData?.result?.responseType === "reply") {
        //       dispatch({
        //         type: UPDATE_CHATBOT_REPLY,
        //         payload: tryAgainData?.result,
        //       });
        //     }
        //   } else {
        //     throw new Error(
        //       tryAgainData?.message ||
        //       "i am sorry , Something went wrong in my system..."
        //     );
        //   }
        // }
      } else {
        throw new Error(
          data?.message || "i am sorry , Something went wrong in my system..."
        );
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: CHATBOT_ERROR, payload: err?.message });
    }
  };

export const getUserProducts =
  ({ choice }) =>
  async (dispatch) => {
    dispatch({ type: CHATBOT_LOADING });

    try {
      const { data } = await axios.post(
        `${CHATBOT_API_URL}/api/v1/chatbot/search/products`,
        { infos: choice }
      );

      if (data?.success) {
        // console.log(data);
        if (data?.result?.responseType === "message") {
          if (data?.result?.content?.length > 0) {
            const botMsg = {
              id: 1,
              isChatbot: true,
              time: `${new Date().getHours()} : ${new Date().getMinutes()}`,
              type: "text",
              content: {
                text: data?.result?.title,
              },
            };
            dispatch({
              type: UPDATE_CHATBOT_CONVERSATION,
              payload: { ...botMsg },
            });
            dispatch({
              type: UPDATE_CHATBOT_CONVERSATION,
              payload: data?.result,
            });
          } else {
            const botMsg = {
              id: 1,
              isChatbot: true,
              time: `${new Date().getHours()} : ${new Date().getMinutes()}`,
              type: "text",
              content: {
                text: "Aucun produit ne correspond à ce type de profil.",
              },
            };
            dispatch({
              type: UPDATE_CHATBOT_CONVERSATION,
              payload: { ...botMsg },
            });
          }

          dispatch({ type: CHATBOT_LOADING });
          const { data: satisfiedData } = await axios.post(
            `${CHATBOT_API_URL}/api/v1/chatbot/event`,
            { event: "otherActionFromUser" }
          );

          if (satisfiedData?.success) {
            dispatch({
              type: UPDATE_CHATBOT_REPLY,
              payload: satisfiedData?.result,
            });
          }
        }

        // dispatch({ type: UPDATE_CHATBOT_CONVERSATION, payload: message });
      } else {
        throw new Error(
          data?.message || "i am sorry , Something went wrong in my system..."
        );
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: CHATBOT_ERROR, payload: err?.message });
    }
  };

export const updateConversation = (message) => (dispatch) => {
  dispatch({ type: UPDATE_CHATBOT_CONVERSATION, payload: message });
};

export const updateChatbotUserChoice = (choice) => (dispatch) => {
  dispatch({ type: CHATBOT_UPDATE_USER_CHOICE, payload: { ...choice } });
};

export const updateCurrentProcess = (process) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_PROCESS, payload: process });
};

export const resetChatbot = () => (dispatch) => {
  dispatch({ type: RESET_CHATBOT });
};

import { UPDATE_CONVERSATION , BOT_LOADING , REQUEST_FAILED , RESET_CONVERSATION } from '../../constants/conversation';

const initialState = {
    loading : true,
    error: null,
    messages: []
}

export const conversationReducer = ( state = initialState , action ) => {
    switch (action.type) {
        case RESET_CONVERSATION : 
            return {
                ...state,
                loading : true,
                error: null,
                messages: []
            }
        case UPDATE_CONVERSATION:
            return {
                ...state,
                loading : false,
                messages : [
                    ...state.messages,
                    {
                        ...action.payload
                    }
                ]
            }
        case BOT_LOADING : 
            return {
                ...state,
                loading : true
            } 
            
        case REQUEST_FAILED : 
        return {
            ...state,
            loading : false,
            error: action.payload
        }
    
        default:
            return state
    }
}
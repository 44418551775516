import { ROOT_CATEGORIES_REQUEST_FAILED, ROOT_CATEGORIES_REQUEST_LOADING, ROOT_CATEGORIES_REQUEST_SUCCESS } from "../../constants/rootCategories";


const initialState = {
    loading: false,
    error: '',
    items : []
}



export const rootCategoriesReducer =  ( state = initialState , action ) => {
    switch (action.type) {
        case ROOT_CATEGORIES_REQUEST_LOADING:
            return {
                ...state,
                loading: true
            }

        case ROOT_CATEGORIES_REQUEST_SUCCESS : 
            return {
                ...state ,
                loading: false,
                items : action.payload
            }
        
        case ROOT_CATEGORIES_REQUEST_FAILED : 
            return {
                ...state,
                loading: false,
                error : action.payload
            } 
    
        default:
           return state;
    }
}
//TRADUIT AVEC GOOGLE

const PODCASTS_TRANSLATIONS_AR = {
  podcastsPageTitle: "Intaliq المدونة الصوتية",
  podcastsPageDescription: "اعثر على كل الأخبار والبودكاست الملهم.",
  podcastsPageContentTitle: "جدول برنامج",
  searchPodcastText: "ابحث عن بودكاست",
  accesPodcastBtn: "الوصول إلى البودكاست",
  programSchedule: "جدول البرنامج",
  mostListened: "الأكثر إستماع",
  mostSearchedTheme: "المواضيع الأكثر شعبية",
  goToDate: "اذهب إلى هذا التاريخ",
  searchPodcasts: "ابحث عن بودكاست",
  searchByMonth: "البحث بالشهر",
  podcasts: "بودكاست",
  addToMyList: "اضف الى المفضلة",
  LesPodcastsDeLentreprise: "بودكاست المقاول ",
  min: "دقائق",
  morePodcasts: "المزيد من البودكاستات",
};

const HEADER_AR = {
  WIKINTALIQ: "موسوعة إنطلق",
  JeVeuxCreeruneEntreprise: "أريد أن أنشأ مقاولتي",
  JeSuisEntrepreneur: "أنا مقاول",
  LAMEDIATHEQUES: "فيديوهات",
  Videos: "فيديو",
  PODCASTEHEADER: "المدونة الصوتية",
  BLOG: "صفحة المقاول",
  CONSULTEZLAFAQ: "إبحث في الأسئلة المتداولة",
  consultTheguide: "راجع الدليل",
  EACADEMY: "الأكاديمية الرقمية",
  DecouvrezGOBOT: "اكتشف GO-BOT",
  PourVousAccompagner: "لمرافقتك",
  EtRepondreAVosQuestions: "والإجابة على أسئلتك",
  SuivezNous: "تابعونا",
  sedeconnecter: "تسجيل الخروج",
  leblog: "صفحة المقاول",
  nosPodcasts: "البودكاست الخاص بنا",
  LaPlateforme: "منصة المقاول ",
  DeLentrepreneur: "",
};

const MEDIATHEQUEENTREPRENEURS_AR = {
  MediaEntrepreneursBlog: "صفحة المقاول",
  ActuMediatheque: "جديد مكتبة الفيديوهات",
  RetrouverVotreBibliotheque: "مكتبتك الخاصة",
  acceesMediatheque: "الولوج لمكتبة الفيديوهات",
  theVideo: "مقاطع الفيديو",
  ofExperts: "من الخبراء",
  moreVideos: "المزيد من مقاطع الفيديو",
};

const HOMEPAGE_TRANSLATIONS_AR = {
  homeTitle: `منصة المقاول`,
  homeDescription: `تستحقون توفر جميع الموارد الأساسية لنجاح مشروعكم`,
  firstBlockText: `دليل عملي`,
  secondBlockText: `مورد للتمويل`,
  thirdBlockText: `فيديوهات و بودكاست كل شهر`,
  chatbotSectionTitle: "chatbotSectionTitle arabe",
  descriptionMediaLibrary:
    "اكتشف اختيارنا من مقاطع الفيديو الخاصة بالخبراء والبودكاستات الملهمة",
  thePodcasts: "البودكاستات",
  fromTheCompany: "من قطاع الأعمال",
  gettingSupportDescription:
    "Intaliq يساعدك في العثور على أفضل شريك ليكون بجانبك!",
  financingEcosystemGuide: "تمويل ودليل النظام البيئي",
};

//TRADUIT AVEC GOOGLE
const GENERAL_TRANSLATIONS_AR = {
  goToBlog: "أتطلع على جميع المقالات",

  nextTime: "قريبا",
  goToDateText: "اذهب إلى هذا التاريخ",
  listenText: "استمع",
  monthText: "شهر",
  yearText: "عام",
  addToMyWishlist: "أضف إلى قائمة أمنياتي",
  mostListened: "الأكثر إستماع",
  // popularThemes: "المواضيع الأكثر شعبية",
  popularThemes: "المواضيع الأكثر شعبية",

  themes: "المواضيع",
  other: "آخر",
  login: "تسجيل الدخول",
  discoverIntaliq: "إنطلق في دقيقتين",
  MyProfile: "الملف الشخصي",
  MyPodcasts: "المدونة الصوتية",
  MyReadingList: "قائمة القراءة الخاصة بي",
  logout: "تسجيل خروج",
  paraFooter:
    "المنصة المخصصة لحاملي المشاريع، المقاولات الناشئة، المقاولات الصغيرة جدا و المقاولات الصغيرة و المتوسطة الذين يبحثون عن معلومات مفيدة و تكوينات عملية و كذا مواكبة تقنية و مالية لتطوير نشاطاتكم.",
  subParaFooter:
    "تم إنجاز هذه المنصة بدعم مالي من الاتحاد الأوروبي. محتواها من مسؤولية CGEM وحدها ولا تعكس بالضرورة وجهات نظر الاتحاد الأوروبي.",
  RESTONSENCONTACT: "لنبقى على إتصال",
  JESUISENTREPRENEUR: "أنا مقاول",
  GuidesDeFinancement: "أدلة التمويل",
  RechercherParMois: "البحث بالشهر",

  DemarrerLaConversation: "ابدأ المحادثة",
  DEMANDERAGOBOT: "اسأل GO-BOT",
  RencontrezGoBot: "تعرف علي رفيقك الألي Go-Bot",
  RestonsEnContact: "نبقى على تواصل",
  MerciANosPartenaires: "شكرا لشركاؤنا",
  Accueil: "إستقبال",

  LEBLOGDESENTREPRENEURS: "صفحة المقاول",
  foireAuxQuestions: "تساؤلات",
  sloganBlog: "اعثر على كل المعلومات والمقالات الملهمة",

  AhmedB: "أحمد. مقاول- الدار البيضاء",
  malak: "ملاك. مقاولة",
  Entrepreneuse: "صاحبة المشروع",
  Casablanca: "الدار البيضاء",
  Agadir: "اكادير",
  Tetouan: "تطوان",
  Entrepreneur: "مقاول",
  ExpertEnMarketing: "مقاول",
  Karim: "كريم. خبير في التسويق الرقمي- تطوان",

  QuestionsFrequemmentPosees: "الأسئلة المتداولة",
  voirPlus: "شاهد المزيد",

  LeBlogDeLEntrepreneur: "موقع المقاول",
  EntrepreneursConcernes: "اهتمامات رجال الأعمال",

  ServicesProposes: "الخدمات المقترحة",
  CoordonneesEtContacts: "تفاصيل الاتصال وجهات الاتصال",

  trouver: "لايجاد",
  demandeAgoBot: "اسأل GO-BOT",
  jechercheFinance: "أبحث عن هيئة للمواكبة",
  jecherchedAccompagnement: "أنا أبحث عن منظمة دعم",
  trouverMeilleurPartenaire: "إبحث عن أحسن شريك لمواكبتك",
  accesServicesFinacier:
    "الولوج لعموم المنتوجات و الخدمات المالية و تحديد المخاطب المناسب",

  MERCIANOSPARTENAIRES: "شكرا لشركائنا",
  LESORGANISMESDACCOMPAGNEMENT: "هيئات الدعم",
  EnSavoirPlus: "اقرأ المزيد",
  RechercherUnOrganisme: "ابحث عن منظمة",

  GuideEcosysteme: "دليل منظمات دعم ريادة الأعمال",
  GuidesIntaliq: "أدلة إنطالك",

  CATEGORIES: "التصنيفات",
  Tous: "الجميع",
  Conseils: "النصيحة",
  Actualites: "أخبار",
  Inspirations: "إلهام",
  Faq: "تساؤلات",
  Mediatheque: "مكتبة الفيديوهات",

  CONNECTEZVOUSAVOTRECOMPTEINTALIQ: "سجّل الدخول إلى حسابك على INTALIQ",
  PasEncoreDeCompte: "لا يوجد حساب بعد",
  motDePasse: "كلمة السر",
  Email: "بريد الالكتروني",
  PasswordLenght: "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل!",
  InputRequired: "هدا الحقل اجباري !",
  emailNotValid: "بريد إلكتروني خاطئ!",
  seConnecter: "الولوج للحساب",
  Sinscrire: "تسجيل",
  seConnecterAvec: "الولوج ب",
  Ou: "أو",

  readMore: "اقرأ أكثر",
  ReadOn: "اقرأ لاحقاً",

  noDataFound: "لا تتفر بيانات",

  findAllInformationsFor: "اعثر على كل المعلومات التي تحتاجها",
  yourEnterprise: "مقاولتكم",
  create: "إنشاء",
  manage: "إدارة",
  develop: "تطوير",
  discoverOurCompleteGuideTo: "استكشف دليلنا الشامل لـ",
  creation: "إنشاء",
  development: "تطوير",
  mediaLibrary: "مكتبة الوسائط",
  companyPodcasts: "les podcasts de l'entreprise",
  download: "يمكنك تحميلها",
  findFinancing: "العثور على تمويل",
  findFinancingDescription: "العثور على هيئة التمويل الخاصة بك",
  findApartner: "العثور على شريك",
  theOpportunity: "الفرص",
  theOpportunityDescription: "اطلع على بنك مشاريعنا للعثور على فكرتك!",
  theOpportunityInvestment: "فرص الاستثمار ",
  companySize: "حجم الشركة",
  activitySector: "قطاع النشاط",
  investissementsOpportunities: "فرص الاستثمار",
};

const PROFILEUSERS_AR = {
  profile: "الملف الشخصي",
  PodcastsFavoris: "ملفات البودكاست المفضلة",
  ListDeLecture: "قائمة القراءة",
  valider: "للتحقق من صحة",
  settings: "ضبط",
  name: "اسم",
  lastname: "الاسم الأول",
  EntrepriseOuProjet: "شركة أو مشروع",
  SecteurActivité: "قطاع النشاط",
  ConfirmerVotreMotDePasse: "أكد رقمك السري *",
  delete: "تم الحذف",
  VoulezVousVraimentLeSupprimer: "هل أنت متأكد أنك تريد حذف ذلك؟",
};

const NEWSLETTER_AR = {
  abonezVous: "اشترك في",
  newsletterIntaliq: "الرسالة الإخبارية لإنطلق",
  actuInatliq:
    "إنخرطوا في الرسالة الإخبارية لإنطلق لتتوصلوا بكل المستجدات الخاصة بريادة الأعمال بالمغرب",
  yourMail: "عنوان بريدك افلكتروني",
  signup: "التسجيل",
  textNewsLetter: "الرسالة الإخبارية",
  sloganNewsLetter:
    "إنخرطوا في الرسالة الإخبارية لإنطلق لتتوصلوا بكل المستجدات الخاصة بريادة الأعمال بالمغرب",
  non: "لا",
  oui: "نعم",
};

const ENTREPRISE_AR = {
  EntrepriseEnDeveloppement: "مقاولة قيد النمو",
  EntrepriseEnCroissance: `مقاولة قيد النمو`,
  EntrepriseEnDeclinRestructuration: "الأعمال في تراجع / إعادة الهيكلة",
  EntrepriseEnTransmission: "مقاولة قيد التفويد",
  PorteurDidee: "حامل فكرة",
  PorteurDeProjet: "حامل لمشروع",
  Startup: "مقاولة ناشئة",
};

const MENU_AR = {
  findOutMore: "اكتشف المزيد من المعلومات",
  gettingSupport: "احصل على الدعم",
  findingOpportunities: "إيجاد الفرص",
  toolbox: "صندوق الأدوات",
  quickSearch: "بحث سريع",
  findYourFinancing: "العثور على تمويلك",
  findApartner: "البحث عن شريك",
  training: "التدريب",
  projectInvestmentBanking: "بنك المشاريع والاستثمار",
  servicesOffered: "خدمات",
};

const HERO_AR = {
  searchATopic: "ابحث عن موضوع",
  smallTextHero: "مثال: تمويل شركتي الناشئة، توظيف المواهب، العثور على شريك،…",
};

const TOOLBOX_AR = {
  entrepreneursToolbox: "صندوق أدوات",
  homeDescriptionToolbox:
    "اعثر على مجموعة من الوثائق النموذجية لتسهيل حياتك اليومية كرائد أعمال.",
  descriptionToolbox:
    "استكشف اختيارنا من الوثائق النموذجية لمساعدتك في حياتك اليومية كرائد أعمال.",
};

const TRAINING_AR = {
  trainingTitle: "مسار تدريب عبر الإنترنت مخصص لرواد الأعمال",
  trainingDescription:
    "أكاديمية 212Founders هي دورة تدريبية جديدة أطلقتها 212Founders لتلبية احتياجات الشركات الناشئة في مرحلة التصور.",
  intaliqAcademy: "founders 212 أكاديمية",
  intaliqButton: "الوصول إلى البرنامج",
  trainingHeroDescription:
    "مسار تدريبي عبر الإنترنت تم إنشاؤه بواسطة 212Founders",
  trainingHomeDescription:
    "بالتعاون مع 212Founders، تقدم لك إنتاليك برنامج تدريبي يستمر لمدة 10 أسابيع للانتقال من الفكرة إلى التمويل.",
  foundersAcademy: "الوصول إلى دورة 212 المؤسسين",
  knowhowTitle: "أكاديمية المعرفة",
  knowhowDescription: "مجموعة من التدريبات والنصائح المجانية لمساعدتك على مواجهة تحديات إدارة الأعمال",
  knowhowAccessButton: "الوصول إلى الدورة",
  traningLoginButton: "تسجيل الدخول للوصول",
};

const FOOTER_AR = {
  policy: "سياسة الخصوصية",
  allRightsReserved: "حقوق النشر © 2022 CGEM. جميع الحقوق محفوظة",
  PourTrouverUnFinancement: "للعثور على تمويل",
  PourMAccompagner: "التي تدعمني",
  organisms: "المنظمات",
  ContactezNous: "اتصل بنا",
  allRights: "من إنتاج CGEM",
};

const BLOG_AR = {
  blog: "صفحة",
};

const ENTREPRENEURS_AR = {
  AbdessamadM: "م. عبد الصمد ",
  AbdessamadEntreprise: "دجيطال برين",
  Hamza: "ر. حمزة ،",
  HamzaEntreprise: "سويت",
  Maha: "ح. مها ",
  MahaEntreprise: "مين برود",
};

export const TRANSLATIONS_AR = {
  ...PODCASTS_TRANSLATIONS_AR,
  ...GENERAL_TRANSLATIONS_AR,
  ...HOMEPAGE_TRANSLATIONS_AR,
  ...NEWSLETTER_AR,
  ...HEADER_AR,
  ...MEDIATHEQUEENTREPRENEURS_AR,
  ...ENTREPRISE_AR,
  ...PROFILEUSERS_AR,
  ...FOOTER_AR,
  ...BLOG_AR,
  ...ENTREPRENEURS_AR,
  ...MENU_AR,
  ...HERO_AR,
  ...TOOLBOX_AR,
  ...TRAINING_AR,
};
